import React, { useState } from 'react';
import './UrlDescriptionBoxes.scss';

const UrlDescriptionBoxes = ({ urls, descriptions }) => {
  const [visibleCount, setVisibleCount] = useState(10);
  const boxCount = Math.min(urls.length, descriptions.length);

  const handleLoadMore = () => {
    setVisibleCount(prevCount => Math.min(prevCount + 10, boxCount));
  };

  return (
    <div className="url-description-container">
      {[...Array(Math.min(visibleCount, boxCount))].map((_, index) => (
        <div key={index} className="url-description-box">
          <h1 className="url-title">{urls[index]}</h1>
          <p className="url-description">{descriptions[index]}</p>
        </div>
      ))}
      {visibleCount < boxCount && (
        <button onClick={handleLoadMore} className="load-more-button">
          Load More
        </button>
      )}
    </div>
  );
};

export default UrlDescriptionBoxes;