import React, { useEffect, useRef, useState } from 'react';
import './ScrollBanner.scss';

const ScrollBanner = ({ items }) => {
    const bannerContentRef = useRef(null);
    const [repeatedItems, setRepeatedItems] = useState([...items]);
    const [ready, setReady] = useState(false);
    const wait = (ms) => new Promise(resolve => setTimeout(resolve, ms));

    useEffect(() => {
        if(items.length === 0){
            return;
        }
        const adjustItems = async () => {
            if (!bannerContentRef.current){
                setTimeout(() => {
                    adjustItems();
                }, 100);
            }
            let bannerWidth = bannerContentRef.current.offsetWidth;
            const containerWidth = bannerContentRef.current.parentElement.offsetWidth;

            //continue duplicating items until the total width is sufficient
            while (bannerWidth < containerWidth * 2) {
                await wait(5);
                bannerWidth = bannerContentRef.current.offsetWidth;
                console.log(containerWidth, bannerWidth)
                setRepeatedItems(prevItems => [...prevItems, ...items]);
            }
            setReady(true);
        };

        adjustItems();
        //readjust on window resize
        window.addEventListener('resize', adjustItems);
        return () => window.removeEventListener('resize', adjustItems);
    }, [items]);

    return (
        <div className="banner-container">
            <div className={`banner-content ${ready ? "" : "not-ready"}`} ref={bannerContentRef}>
                    <span className="banner-item">
                        I
                    </span>
                {repeatedItems.map((item, index) => (
                    <span className="banner-item" key={index}>
                        {item}
                    </span>
                ))}
            </div>
        </div>
    );
};

export default ScrollBanner;