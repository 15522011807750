import React, { useRef, useEffect, useState } from "react";
import "./LineStatDisplay.scss";

import Label from "../Label/Label";
import { ReactComponent as ShareIcon } from "../../resources/shareIcon.svg";
import AnimatedCheckIcon from "../AnimatedCheckToX/AnimatedCheckToX";
import FilterSwitch from "../Switch/Switch";
import FancyProgressBar from "./FancyProgressBar/FancyProgressBar";
import DailyWaffleWidget from "./DailyWaffleWidget/DailyWaffleWidget";

const LineStatDisplay = ({stats, participants, target}) => {
  // hard coded for testing, swap out with API call
  const [values, setValues] = useState(stats.values);
  const [thresholds, setThresholds] = useState(stats.thresholds);
  const [colors, setColors] = useState(stats.colors);
  const [initialDate, setInitialDate] = useState(new Date(stats.initialDate));
  const [currentParticipants, setCurrentParticipants] = useState(participants);
  const [targetParticipants, setTargetParticipants] = useState(target);

  useEffect(() => {
    setValues(stats.grid);
    setThresholds(stats.thresholds);
    setColors(stats.colors);
    setInitialDate(new Date(stats.initialDate));
    setCurrentParticipants(participants);
    setTargetParticipants(target);
  }, [stats,participants, target]);

  const [joined, setJoined] = useState(false);
  const [fadeOut, setFadeOut] = useState(false);
  const [frameWidth, setFrameWidth] = useState(0);
  const [maxWidth, setMaxWidth] = useState(0);
  const [filterToggle, setFilterToggle] = useState(false);
  const [impactGridStartValue, setImpactGridStartValue] = useState(0);
  const [impactGridEndValue, setImpactGridEndValue] = useState(-1);

  const [rangeStartValue, setStartRangeValue] = useState(initialDate);
  const [rangeEndValue, setEndRangeValue] = useState(new Date("2024-08-20"));

  const waffleContainerRef = useRef(null);
  const filterSwitchRef = useRef(null);

  const calculateMaxWidth = () => {
    if (filterSwitchRef.current) {
      // Force a reflow to ensure we get the correct size
      filterSwitchRef.current.style.display = "inline-block";
      const width =
        waffleContainerRef.current.getBoundingClientRect().width -
        filterSwitchRef.current.offsetWidth;
      filterSwitchRef.current.style.display = "";
      setMaxWidth(width);
    }
  };

  const calculateFrameWidth = () => {
    if (waffleContainerRef.current) {
      const width = waffleContainerRef.current.getBoundingClientRect().width;
      setFrameWidth(width);
    }
  };

  const joinToggle = () => {
    setFadeOut(true);
    setTimeout(() => {
      setJoined(!joined);
      setFadeOut(false);
    }, 250);
  };

  const filterSwap = (index) => {
    if (index === 0) {
      // range
      setFilterToggle(false);
    } else {
      // month
      setFilterToggle(true);
    }

    setTimeout(calculateMaxWidth, 0);
  };

  const rangeStartChange = (e) => {
    if (e.target.value != "") {
      const newValue = e.target.value;
      setStartRangeValue(new Date(newValue));
    } else {
      setStartRangeValue(initialDate);
    }
    updateImpactGrid();
  };

  const rangeEndChange = (e) => {
    if (e.target.value != "") {
      const newValue = e.target.value;
      setEndRangeValue(new Date(newValue));
    } else {
      setEndRangeValue(new Date());
    }
    updateImpactGrid();
  };

  const safelyConvertToDate = (value) => {
    if (value instanceof Date) return value;
    if (typeof value === "string") {
      const date = new Date(value);
      return isNaN(date.getTime()) ? null : date;
    }
    return null;
  };

  const updateImpactGrid = () => {
    const currentDate = new Date();
    const initialDateInDays = Math.floor(initialDate.getTime() / 86400000);
    const currentDateInDays = Math.floor(currentDate.getTime() / 86400000);

    // Safely convert rangeStartValue and rangeEndValue to Date objects
    const startDate = safelyConvertToDate(rangeStartValue);
    const endDate = safelyConvertToDate(rangeEndValue);

    // Convert to days since epoch
    const rangeStartValueInDays = Math.floor(startDate.getTime() / 86400000);
    const rangeEndValueInDays = Math.floor(endDate.getTime() / 86400000);

    // Calculate days from initialDate
    let startValue = rangeStartValueInDays - initialDateInDays;
    let endValue = rangeEndValueInDays - initialDateInDays;

    // Apply the conditions
    if (startValue > endValue) {
      startValue = 0;
    } else if (startValue < 0) {
      startValue = 0;
    }

    if (endValue < startValue) {
      endValue = -1;
    } else if (endValue > currentDateInDays - initialDateInDays) {
      endValue = -1;
    }

    // Update the state
    setImpactGridStartValue(startValue);
    setImpactGridEndValue(endValue);
  };

  const formatDateForInput = (date) => {
    return date.toISOString().split("T")[0];
  };

  useEffect(() => {
    calculateFrameWidth();
    calculateMaxWidth();
    window.addEventListener("resize", calculateFrameWidth);
    window.addEventListener("resize", calculateMaxWidth);
    return () => {
      window.removeEventListener("resize", calculateFrameWidth);
      window.removeEventListener("resize", calculateMaxWidth);
    };
  }, []);

  return (
    <span className="joined-friends-wrapper">
      <span className="stat-display-column">
        <FancyProgressBar
          currentParticipants={currentParticipants}
          targetValue={targetParticipants}
          subdivisions={12}
          maxWidth={frameWidth}
          currentSubheading="current"
          targetSubheading="target"
        />
        <span className="waffle-x-control-container" ref={waffleContainerRef}>
          <Label className="waffle-impact-label" label="impact">
            {/* ERROR: Currently a slight miss-calc for the max-wdith that pushes the cal to the right too much
            might be fixed when we switch calenders, also sometimes fixes it'self idk it's weird */}
            <DailyWaffleWidget
              values={values}
              thresholds={thresholds}
              colors={colors}
              toolTip={"people held the line on"}
              initialDate={initialDate}
              startIndex={impactGridStartValue}
              endIndex={impactGridEndValue}
              maxWidth={maxWidth - 20}
              maxHeight={210}
              gap={2}
            />
          </Label>

          <div className="line-filter-container" ref={filterSwitchRef}>
            <span className="filter-column">
              <div>
                <Label label="start">
                  <input
                    className="waffle-year-range"
                    type="date"
                    value={formatDateForInput(rangeStartValue)}
                    onChange={rangeStartChange}
                  />
                </Label>

                <Label label="end">
                  <input
                    className="waffle-year-range"
                    type="date"
                    value={formatDateForInput(rangeEndValue)}
                    onChange={rangeEndChange}
                  />
                </Label>
              </div>

              {/* <Label label="today"> */}
              {/* <div className="todays-stat-box"></div> */}

              {/* </Label> */}
            </span>
          </div>
        </span>
        <span className="line-buttons-container">
          <div
            className={`line-button ${joined ? "joined" : ""}`}
            onClick={joinToggle}
          >
            <div className={`button-content ${fadeOut ? "fade-out" : ""}`}>
              {joined ? (
                //  leaving this here for now incase we decide to go back to the static check
                // <CheckIcon className='check-icon'/>
                // ERROR: the hitbox for the check icon does not include the border and when i try and add it in it breaks the alignment... low prio but not sure how to fix
                <AnimatedCheckIcon
                  className="check-icon"
                  size="18px"
                  compensatePadding={{ top: "20px" }}
                />
              ) : (
                <span>Join</span>
              )}
            </div>
          </div>
          <div className={`line-share-button ${joined ? "joined" : ""}`}>
            {/* UPGRADE: might want to add an anamation here for diffrent ways to share */}
            <ShareIcon className="share-icon" />
          </div>
        </span>
      </span>
      {/* ERROR: should maybe add somthing so this doesn't pop-in and out if you joined and leave, just disappearing once and not coming back */}
      {/* Should this be centered? */}
      {joined ? null : (
        <div className={`joined-friends-display ${fadeOut ? "fade-out" : ""}`}>
          {" "}
          Join {"Adam watson"}, {"Valery"}, and {"3"} others.
        </div>
      )}
    </span>
  );
};

export default LineStatDisplay;
