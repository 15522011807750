import React, { useState, useEffect } from "react";
import "./Search.scss";
import { useData } from "../../contexts/DataContext";

import NavBar from "../../components/NavBar/NavBar";
import ScrollingBanner from "../../components/ScrollBanner/ScrollBanner";
import Label from "../../components/Label/Label";
import SearchFilterSelector from "../../components/SearchFilterSelector/SearchFilterSelector";
import LinePreview from "../../components/ListedLinePreview/ListedLinePreview";

import tempPFPLineOwner from "../../resources/highResPFPTest.jpg";
import exampleLineCover from "../../resources/testLineCover.jpg";

// Fake data for LinePreview components
const fakeLines = [
  {
    title: "Eco-Friendly Products",
    bio: "Discover sustainable and environmentally friendly products for everyday use. From reusable bags to bamboo utensils, we've got you covered!",
    participantCount: 1453,
  },
  {
    title: "Gourmet Coffee Collection",
    bio: "Explore our curated selection of premium coffee beans from around the world. Perfect for coffee enthusiasts and connoisseurs.",
    participantCount: 892,
  },
  {
    title: "Tech Gadgets Galore",
    bio: "Stay up-to-date with the latest tech gadgets and accessories. Find everything from smart home devices to cutting-edge wearables.",
    participantCount: 2105,
  },
  {
    title: "Handmade Crafts",
    bio: "Support local artisans and discover unique, handcrafted items. From jewelry to home decor, each piece tells a story.",
    participantCount: 736,
  },
  {
    title: "Healthy Recipes",
    bio: "Explore a collection of delicious and nutritious recipes. From vegan meals to gluten-free options, there's something for everyone.",
    participantCount: 1024,
  },
  {
    title: "Outdoor Adventure Gear",
    bio: "Gear up for your next outdoor adventure with our selection of high-quality gear and equipment. From camping essentials to hiking gear, we've got you covered.",
    participantCount: 1897,
  },
  {
    title: "Home Workout Essentials",
    bio: "Stay fit and active with our range of home workout essentials. From resistance bands to yoga mats, we have everything you need for a great workout.",
    participantCount: 521,
  },
  {
    title: "Pet Supplies",
    bio: "Find everything you need to keep your furry friends happy and healthy. From pet food to toys, we have a wide selection of pet supplies.",
    participantCount: 1346,
  },
  {
    title: "Fashion Trends",
    bio: "Stay stylish and up-to-date with the latest fashion trends. Discover new looks and outfit ideas for every season.",
    participantCount: 978,
  },
  {
    title: "Books Worth Reading",
    bio: "Expand your reading list with our handpicked selection of books. From bestsellers to hidden gems, there's a book for every reader.",
    participantCount: 2053,
  }
];

function Search() {
  const { fetchData } = useData();
  const [picketts, setPicketts] = useState([]);

  useEffect(() => {
    fetchData("/api/picketts").then((response) => {
      setPicketts(response.data);
    });
  }, []);

  return (
    <div className="search page">
       <ScrollingBanner items={picketts.map((pickett) => pickett.name)} />
      
      <div className="search-content">
        <div className="search-filter">
          <SearchFilterSelector />
        </div>
        <div className="search-results">
          {picketts.map((picketts, index) => (
              <React.Fragment key={index}>
              <LinePreview 
                title={picketts.name}
                bio={picketts.bio}
                participantCount={picketts.participants}
                imageSource={picketts.coverPic}
              />
              <div className="searched-line-spacer-temp" style={{ marginBottom: "10px" }}></div>
            </React.Fragment>
          ))}
        </div>
      </div>
      {/* TO-DO: Add more clear of a call for them to make a line here, also add a state where when 
      there are no matching searches the button moves to middle and grows and asks them to 
      make a new line, anamation needs a bit mroe thought. 

      Also needs a loading anaimation unless it's v fast and possible an anaimation for them to like fade or drop in
       */}
      <div className="pinned-new-line-wrapper">+</div>
    </div>
  );
}

export default Search;