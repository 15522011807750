import React, { useState, useEffect } from 'react';
import RegisterForm from '../../components/Forms/RegisterForm/RegisterForm';
import { useLocation } from 'react-router-dom';
import './Login.scss';

const Register = () => {
    const location = useLocation();
    const emailParams = new URLSearchParams(location.search).get('email');
    const email = emailParams ? JSON.parse(emailParams) : null;
    return (
        <div className="register">
            <div className="left">

            </div>
            <div className="right">
            {
                    email ?
                    <RegisterForm email={email} />
                    :
                    <RegisterForm />
                }
            </div>
        </div>
    );
}

export default Register;