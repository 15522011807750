import React, { useState, useEffect, useRef, useCallback } from "react";
import "./Landing.scss";
import { useData } from "../../contexts/DataContext";
import { Icon } from "@iconify-icon/react";

import { useNavigate } from "react-router-dom";

import LineSelector from "../../components/LineSelector/LineSelector";
import DailyImpactGrid from "../../components/LineStatDisplay/DailyWaffleWidget/DailyWaffleWidget";
import Label from "../../components/Label/Label";
import ScrollingBanner from "../../components/ScrollBanner/ScrollBanner";
import EmailInput from "../../components/TextInput/TextInput";
import Footer from "../../components/Footer/Footer";

import NumberScroller from "../../components/NumberScroller/NumberScroller";

function Landing() {
    const navigate = useNavigate();
    const pageRef = useRef(null);
  const { fetchData } = useData();
  const [picketts, setPicketts] = useState([]);
  const [values, setValues] = useState([]);

  const [statTargets, setStatTargets] = useState({
    stat1: 0,
    stat2: 0,
    stat3: 0,
    stat4: 0,
    stat5: 0,
    stat6: 0,
  });

  useEffect(() => {
    fetchData("/api/get-landing-data").then((data) => {
      setValues(data.impact);
      setStatTargets(data.stats);
    });
    fetchData("/api/picketts").then((data) => {
      setPicketts(data.data);
      console.log(data.data);
    });
  }, []);

  const [isStatsVisible, setIsStatsVisible] = useState(false);
  const statsRef = useRef(null);

  const thresholds = [1, 10, 15, 25, 30];
  const colors = ["#EDEDED", "#F2C4C1", "#F29C97", "#F1746D", "#EB675F"];
  const initialDate = new Date("2023-08-20");
  const [maxWidth, setMaxWidth] = useState(0);
  const [screenWidth, setScreenWidth] = useState(window.innerWidth);

  const waffleContainerRef = useRef(null);

  const calculateMaxWidth = () => {
    setScreenWidth(window.innerWidth);
    const width = waffleContainerRef.current.getBoundingClientRect().width;
    setMaxWidth(width);
  };


  const jumpPage = () => {
    if(pageRef.current){
        pageRef.current.scrollTo({ top: window.innerHeight, behavior: "smooth" });
    }
  };

  const fillLogin = (email) => {
    const encodedEmail = encodeURIComponent(`"${email}"`);
    navigate(`/register?email=${encodedEmail}`);
  }

  useEffect(() => {
    calculateMaxWidth();
    window.addEventListener("resize", calculateMaxWidth);

    const observer = new IntersectionObserver(
      ([entry]) => {
        setIsStatsVisible(entry.isIntersecting);
      },
      {
        root: null,
        rootMargin: "0px",
        threshold: 0.1,
      }
    );

    if (statsRef.current) {
      observer.observe(statsRef.current);
    }

    return () => {
      window.removeEventListener("resize", calculateMaxWidth);
      if (statsRef.current) {
        observer.unobserve(statsRef.current);
      }
    };
  }, [fetchData]);

  return (
    <div className="Landing page" ref={pageRef}>
      {/* <NavBar /> */}
      {/* temp spacing will fix when all compontents are finalized and i'm actually putting them together correctly... soon I promise */}

      <ScrollingBanner items={picketts.map((pickett) => pickett.name)} />

      <div className="landing-initial-frame">
        <div className="landing-initial-view-x-down-arrow-bounding">
          <div className="landing-bounding-box-one">
            <div className="landing-text-and-button-wrapper">
              <div className="landing-text-wrapper">
                <h1 className="landing-title">
                  What's your <span className="title-underline">line</span>?
                </h1>
                <p className="landing-description">
                  {" "}
                  The all-in-one platform for social impact at scale. Join hundreds of motivated individuals looking to leverage collective action for good.
                </p>
              </div>
              <div className="landing-button-wrapper">
                <EmailInput
                  emptyInputText="Enter your email"
                  submitButtonText="Join"
                  onSubmit={fillLogin}
                />
              </div>{" "}
            </div>

            <Label label="daily impact">
              <div
                className="impact-grid-bounding-box"
                ref={waffleContainerRef}
              >
                
                <DailyImpactGrid
                  values={values}
                  thresholds={thresholds}
                  colors={colors}
                  toolTip={"people held the line on"}
                  initialDate={initialDate}
                  startIndex={0}
                  endIndex={-1}
                  maxWidth={maxWidth}
                maxHeight={screenWidth < 768 ? 200 : 300}
                  gap={2}
                />
              </div>
            </Label>
          </div>
          <div className="landing-arrow-down" onClick={jumpPage}>
            <Icon icon="fa6-solid:chevron-down" />
          </div>
        </div>
      </div>

      <div
        style={{
          width: "100%",
          height: "auto",
          backgroundColor: "#35353b",
          display: "flex",
          justifyContent: "center",
        }}
      >
        <div className="landing-bounding-box-two">
          <div className="landing-text-and-button-wrapper">
            <div className="landing-text-wrapper">
              <h1 className="landing-title">
                Collective action starts with{" "}
                <span className="title-underline"> you</span>.
              </h1>
            </div>
          </div>

          <div className="temp-stats-home" ref={statsRef}>
            <div className="stat-container">
                <div className="landing-stat-wrapper">
                    <h1 className="landing-stat-main">
                        <NumberScroller
                        number={statTargets.stat1}
                        delayPerDigit={0.2}
                        durationPerDigit={1}
                        startAnimation={isStatsVisible}
                        />
                    </h1>
                    <h4 className="landing-stat-sub-header">stat</h4>
                </div>
                <div className="landing-stat-wrapper">
                    <h1 className="landing-stat-main">
                        <NumberScroller
                        number={statTargets.stat2}
                        delayPerDigit={0.2}
                        durationPerDigit={1.2}
                        startAnimation={isStatsVisible}
                        />
                    </h1>
                    <h4 className="landing-stat-sub-header">stat</h4>
                </div>
                <div className="landing-stat-wrapper">
                    <h1 className="landing-stat-main">
                        <NumberScroller
                        number={statTargets.stat3}
                        delayPerDigit={0.2}
                        durationPerDigit={1.4}
                        startAnimation={isStatsVisible}
                        />
                    </h1>
                    <h4 className="landing-stat-sub-header">stat</h4>
                </div>
            </div>
            <div className="stat-container">
                <div className="landing-stat-wrapper">
                <h1 className="landing-stat-main">
                    <NumberScroller
                    number={statTargets.stat4}
                    delayPerDigit={0.2}
                    durationPerDigit={1.6}
                    startAnimation={isStatsVisible}
                    />
                </h1>
                <h4 className="landing-stat-sub-header">stat</h4>
                </div>
                <div className="landing-stat-wrapper">
                <h1 className="landing-stat-main">
                    <NumberScroller
                    number={statTargets.stat5}
                    delayPerDigit={0.2}
                    durationPerDigit={1.8}
                    startAnimation={isStatsVisible}
                    />
                </h1>
                <h4 className="landing-stat-sub-header">stat</h4>
                </div>
                <div className="landing-stat-wrapper">
                <h1 className="landing-stat-main">
                    <NumberScroller
                    number={statTargets.stat6}
                    delayPerDigit={0.2}
                    durationPerDigit={2}
                    startAnimation={isStatsVisible}
                    />
                </h1>
                <h4 className="landing-stat-sub-header">stat</h4>
                </div>
            </div>
          </div>
        </div>
      </div>

      {/* termp centering because it was annoying me that it was so far right */}
      <div style={{ width: "100%", display: "flex", justifyContent: "center" }}>
        <LineSelector setPreviewInfo={()=>{}}/>
      </div>
      <Footer />
    </div>
  );
}

export default Landing;
