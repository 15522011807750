import React, { useState, useEffect } from "react";
import "./Line.scss";
import { useData } from "../../contexts/DataContext";
import { useParams } from "react-router-dom";
import { useNavigate } from "react-router-dom";

import NavBar from "../../components/NavBar/NavBar";
import RelatedLineViewer from "../../components/LineViewer/LineViewer";
import ScrollingBanner from "../../components/ScrollBanner/ScrollBanner";
import LineStatDisplay from "../../components/LineStatDisplay/LineStatDisplay";
import LineBioRenderer from "../../components/HtmlCssRenderer/HtmlCssRenderer";
import IncludedLinesList from "../../components/UrlDescriptionBoxes/UrlDescriptionBoxes";
import Label from "../../components/Label/Label";
import Switch from "../../components/Switch/Switch";

import tempPFPLineOwner from "../../resources/highResPFPTest.jpg";
import exampleLineCover from "../../resources/testLineCover.jpg";

function Line() {
  const name = useParams().name;
  const { fetchData } = useData();
  // in this case picketts should be related lines
  const [picketts, setPicketts] = useState([]);
  const [line, setLine] = useState(null);
  const [owner, setOwner] = useState(null);
  const [fetching, setFetching] = useState(true);

    const navigate = useNavigate();

  useEffect(() => {
    fetchData('/api/line', "get", null, {params: {name:name}}).then((response) => {
      if(response.success) {
          fetchData(`/api/user`, "get", null, {params: {id: response.data.lineOwnerId}}).then((response) => {
            console.log(response);
            if(response.success) {
              setOwner(response.data);
            } else {
              console.log(response.message);
            }
            setFetching(false);
          })
          .catch((error) => {
            console.log(error);
            setFetching(false);
            });
        setLine(response.data);
      } else {
        console.log(response.message);
      }
    })
    .catch((error) => {
        console.log(error);
        setFetching(false);
    });
    
    fetchData('/api/picketts').then((response) => {
        setPicketts(response.data);
        console.log(response.data);
    }   
    );
  }, [fetchData]);

  //keeping track of width
    const [width, setWidth] = useState(window.innerWidth);
    useEffect(() => {
      const handleResize = () => setWidth(window.innerWidth);
      window.addEventListener('resize', handleResize);
      return () => {
        window.removeEventListener('resize', handleResize);
      };
    }, []);
    const [showBio, setShowBio] = useState(true); //for toggling between bio and sites
    const handleBioSwitch = (index) => {
      setShowBio(index === 0);
    }

    if(fetching){
        return (
            <div className="line">
            </div>
        );
    }

  return (
    <div className="line page">
      {/* <NavBar /> */}
      {/* temp spacing will fix when all compontents are finalized and i'm actually putting them together correctly... soon I promise */}
      <ScrollingBanner items={picketts.map((pickett) => pickett.name)} />
       <div className="line-page-contents">
        <span className="title-cover-alignment">
          <span className="title-card">
          <h1 className="line-title">{name}</h1>
          {owner && (
          <span className={`line-owner-tag`}
            onClick={()=>{navigate(`/profile/${owner.username}`)}}
          >

              <div className="line-owner-pfp-container">
                
                <img
                className="line-owner-pfp-home"
                src={tempPFPLineOwner}
                alt={`${owner.username} profile picture`}
                />
  
              </div>
              {owner.username}
          
            </span>
          )}
          </span>
          <span className="cover-and-stat-alignment">
            <img className="line-cover" src={tempPFPLineOwner}></img>
           { line && ( <LineStatDisplay stats={line.stats} participants={line.participants} target={line.target}/> )}
          </span>
          {
            width <= 500 &&
            <div className="bio-switch-container">
                <Switch options={["Bio", "Included Sites"]} onChange={handleBioSwitch} mFSize={15}/>
            </div>
            
          }
          <div className={`line-bio-and-included-sites ${showBio ? "" : "included-sites"}`}>
            <div className="line-bio-wrapper">
              {/* ERROR: This is potentially risky for js injections I think, we can heavily modify the text since we're making it with them */}
              {/* Other potential Issue is this adds alot of potential work on the line creation, might swap this for plain text for launch till v2 */}
              {/* UPDATE: Risk should be mitigated since we swapped html renderer */}
              {line && (
              <LineBioRenderer 
              htmlContent={line.html}
              cssContent={line.css}
              />
)}
              {/* i think this should toggle between 20 vs 15px margin-top but it would make  */}
            {/* the code so much less clean... */}
            <div className="related-lines-container">
                <RelatedLineViewer title="related lines" lines={picketts} />
            </div>
            </div>
            <div className="included-lines-wrapper">
             
            <Label className="included-lines-label" label={`${width <= 500 ? "" : "included-sites"}`} >
              {line && ( 
                <IncludedLinesList urls={line.urls} descriptions={line.descriptions} />
              )}
                </Label>
             </div>
            
          </div>
      </span>
      </div>
    </div>
  );
}

export default Line;
