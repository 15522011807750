import React from 'react';
import "./SearchBar.scss";
import { ReactComponent as SearchIcon } from "../../resources/searchIcon.svg";

const SearchBar = ({ backgroundColor = 'var(--text-color)', textColor = 'var(--background-color)', placerHolderText = "Search...", onSearch }) => {
    const handleKeyPress = (event) => {
        if (event.key === 'Enter' && event.target.tagName === 'INPUT') {
            onSearch(event.target.value);
        }
    };

    const handleSearchClick = (event) => {
        onSearch(document.querySelector('.search-bar-input').value);
    }

    return (
        <div className='search-bar' style={{ '--custom-bg-color': backgroundColor, '--custom-text-color': textColor }}>
            <input
                className='search-bar-input'
                type="text"
                placeholder={placerHolderText}
                onKeyPress={handleKeyPress}
            />
            <div className='search-bar-cap' onClick={handleSearchClick}>
                <SearchIcon className='search-icon'/>
            </div>
        </div>
    );
};

export default SearchBar;