import React, { useState, useMemo, useRef, useEffect } from "react";
import "./LineEntry.scss";
import { Icon } from "@iconify-icon/react";

const LineEntry = ({
  onValidEntry,
  onNewLine,
  onRemoveLine,
  index,
  initialData,
  shouldFocus,
  focusInput,
  onFocusComplete,
  isLastLine,
  hasMultipleLines,
  onNavigateToLine,
  totalLines,
  // dragHandleProps,
  // isDragging,
  // showDropIndicator,
}) => {
  const [altItems, setAltItems] = useState(initialData?.altItems || []);
  const [altInputValue, setAltInputValue] = useState("");
  const [urlValue, setUrlValue] = useState(initialData?.mainUrl || "");
  const [descValue, setDescValue] = useState(initialData?.description || "");
  const [itemsToRemove, setItemsToRemove] = useState(new Set());
  const [showUrlError, setShowUrlError] = useState(false);
  const [showAltError, setShowAltError] = useState(false);
  const [showDescError, setShowDescError] = useState(false);
  
  // Store temporary values during error states
  const [tempAltValue, setTempAltValue] = useState("");
  const [tempDescValue, setTempDescValue] = useState("");

  // Timeout refs for clearing inputs
  const urlTimeoutRef = useRef(null);
  const descTimeoutRef = useRef(null);
  const altTimeoutRef = useRef(null);

  const urlInputRef = useRef(null);
  const descInputRef = useRef(null);
  const altInputRef = useRef(null);

  const MAX_DESC_LENGTH = 200;
  const MAX_ALT_ITEMS = 5;
  const ERROR_DISPLAY_TIME = 1000;
  const INPUT_DELAY = 200;

  const refs = {
    url: urlInputRef,
    desc: descInputRef,
    alt: altInputRef,
  };

  useEffect(() => {
    if (shouldFocus && refs[focusInput]?.current) {
      refs[focusInput].current.focus();
      onFocusComplete();
    }
  }, [shouldFocus, focusInput]);

  const clearTimeouts = () => {
    if (urlTimeoutRef.current) clearTimeout(urlTimeoutRef.current);
    if (descTimeoutRef.current) clearTimeout(descTimeoutRef.current);
    if (altTimeoutRef.current) clearTimeout(altTimeoutRef.current);
  };

  useEffect(() => {
    return () => clearTimeouts();
  }, []);

  useEffect(() => {
    if (showUrlError) {
      const timer = setTimeout(() => setShowUrlError(false), ERROR_DISPLAY_TIME);
      return () => clearTimeout(timer);
    }
  }, [showUrlError]);

  useEffect(() => {
    if (showAltError) {
      setTempAltValue(altInputValue);
      altTimeoutRef.current = setTimeout(() => {
        setAltInputValue("");
      }, INPUT_DELAY);

      const timer = setTimeout(() => {
        setShowAltError(false);
        setAltInputValue(tempAltValue);
      }, ERROR_DISPLAY_TIME);

      return () => {
        clearTimeout(timer);
        clearTimeout(altTimeoutRef.current);
      };
    }
  }, [showAltError]);

  useEffect(() => {
    if (showDescError) {
      setTempDescValue(descValue);
      descTimeoutRef.current = setTimeout(() => {
        setDescValue("");
      }, INPUT_DELAY);

      const timer = setTimeout(() => {
        setShowDescError(false);
        setDescValue(tempDescValue);
      }, ERROR_DISPLAY_TIME);

      return () => {
        clearTimeout(timer);
        clearTimeout(descTimeoutRef.current);
      };
    }
  }, [showDescError]);

  const isLineEmpty = () => {
    return !urlValue && !descValue && !altInputValue && altItems.length === 0;
  };

  const tryCreateNewLine = (currentAltValue = "") => {
    if (!urlValue.trim()) {
      setShowUrlError(true);
      urlInputRef.current?.focus();
      return;
    }

    if (currentAltValue) {
      if (altItems.length >= MAX_ALT_ITEMS) {
        setShowAltError(true);
        return;
      }
      const newAltItems = [...altItems, currentAltValue.trim()];
      setAltItems(newAltItems);
      setAltInputValue("");
      notifyValidEntry(newAltItems);
    }

    if (isLastLine) {
      onNewLine(index, getCurrentData());
    }
  };

  const handleBackspace = (e, inputType) => {
    if (e.key === "Backspace" && isLineEmpty() && hasMultipleLines) {
      e.preventDefault();
      onRemoveLine(index, inputType);
    }
  };

  const notifyValidEntry = (alternatives = altItems) => {
    onValidEntry({
      index,
      url: urlValue,
      description: descValue,
      alternatives,
    });
  };

  const handleEnterKey = (currentAltValue = "") => {
    if (currentAltValue) {
      if (altItems.length >= MAX_ALT_ITEMS) {
        setShowAltError(true);
        return;
      }
      const newAltItems = [...altItems, currentAltValue.trim()];
      setAltItems(newAltItems);
      setAltInputValue("");
      notifyValidEntry(newAltItems);
    }

    if (isLastLine) {
      onNewLine(index);
    }
  };

  const handleKeyDown = (e, inputType) => {
    if (e.key === "Enter") {
      e.preventDefault();
      handleEnterKey(inputType === "alt" ? altInputValue : "");

      if (e.shiftKey) {
        if (index > 0) {
          onNavigateToLine(index - 1, inputType);
        }
        return;
      }

      if (isLastLine) {
        tryCreateNewLine(inputType === "alt" ? altInputValue : "");
      } else {
        onNavigateToLine(index + 1, inputType);
      }
    } else if (e.key === "Backspace") {
      handleBackspace(e, inputType);
    }
  };

  const getCurrentData = () => ({
    index,
    url: urlValue,
    description: descValue,
    alternatives: altItems,
  });

  const handleAltInputKeyDown = (e) => {
    if (e.key === "Tab") {
      if (!e.shiftKey) {
        e.preventDefault();
      }
      if (altItems.length >= MAX_ALT_ITEMS) {
        setShowAltError(true);
        return;
      }
      if (altInputValue.trim()) {
        const newAltItems = [...altItems, altInputValue.trim()];
        setAltItems(newAltItems);
        setAltInputValue("");
        notifyValidEntry(newAltItems);
      }
    } else if (e.key === "Enter") {
      handleKeyDown(e, "alt");
    } else if (e.key === "Backspace") {
      handleBackspace(e, "alt");
    }
  };

  const handleInputChange = (e, type) => {
    const value = e.target.value;
    if (type === "url") {
      setUrlValue(value);
    } else if (type === "desc") {
      if (value.length <= MAX_DESC_LENGTH) {
        setDescValue(value);
      } else {
        setTempDescValue(descValue);
        setShowDescError(true);
      }
    }
    notifyValidEntry();
  };

  const handleBlur = () => {
    setTimeout(() => {
      if (urlValue || descValue) {
        notifyValidEntry();
      }
    }, 0);
  };

  const handleRemoveItem = (index) => {
    setItemsToRemove(new Set([...itemsToRemove, index]));

    setTimeout(() => {
      const newAltItems = altItems.filter((_, i) => i !== index);
      setAltItems(newAltItems);
      setItemsToRemove((prev) => {
        const next = new Set(prev);
        next.delete(index);
        return next;
      });
      notifyValidEntry(newAltItems);
    }, 300);
  };

  const { inputWrapperWidth, altItemsWidth } = useMemo(() => {
    const itemWidth = 15;
    const totalItemsWidth = Math.min(altItems.length * itemWidth, 40);
    return {
      inputWrapperWidth: 100 - totalItemsWidth,
      altItemsWidth: totalItemsWidth,
    };
  }, [altItems.length]);

  return (
    // <div className={`line-entry ${isDragging ? "dragging" : ""}`}>
    <div className="line-entry">
      {/* <div className="drag-handle-wrapper" {...dragHandleProps}>
        <Icon icon="uil:draggabledots" />
      </div> */}
      {/* {showDropIndicator && <div className="drop-indicator active" />} */}
      <div
        className="url-entry-input-wrappers"
        style={{ width: `${inputWrapperWidth}%` }}
      >
        <input
          ref={urlInputRef}
          className={`url-input ${showUrlError ? "error" : ""}`}
          type="text"
          value={urlValue}
          onChange={(e) => {
            setUrlValue(e.target.value);
            setShowUrlError(false);
          }}
          onKeyDown={(e) => handleKeyDown(e, "url")}
          onBlur={handleBlur}
          placeholder={showUrlError ? "url is required" : ""}
        />
        <input
          ref={descInputRef}
          className={`description-input ${showDescError ? "error" : ""}`}
          type="text"
          value={descValue}
          onChange={(e) => handleInputChange(e, "desc")}
          onKeyDown={(e) => handleKeyDown(e, "desc")}
          onBlur={handleBlur}
          placeholder={showDescError ? `maximum ${MAX_DESC_LENGTH} characters` : ""}
        />
        <input
          ref={altInputRef}
          className={`alternative-input ${showAltError ? "error" : ""}`}
          type="text"
          placeholder={showAltError ? `maximum ${MAX_ALT_ITEMS} items` : ""}
          value={altInputValue}
          onChange={(e) => setAltInputValue(e.target.value)}
          onKeyDown={handleAltInputKeyDown}
          onBlur={handleBlur}
        />
      </div>
      <div
        className="alternative-items-list-wrapper"
        style={{ width: `${altItemsWidth}%` }}
      >
        {altItems.map((item, idx) => (
          <div
            key={idx}
            className={`alt-item ${itemsToRemove.has(idx) ? "removing" : ""}`}
            onClick={() => handleRemoveItem(idx)}
          >
            <span className="item-text">{item}</span>
            <span className="delete-x">×</span>
          </div>
        ))}
      </div>
    </div>
  );
};

export default LineEntry;