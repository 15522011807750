import React from 'react';
import './PickettList.scss';

import MassUrlEntry from '../../MassUrlEntry/MassUrlEntry';

const PickettList = ({previewInfo, setPreviewInfo, setNext}) => {
    return (
        <div className="pickett-list">
            <div className="pickett-list-scroll">
                <MassUrlEntry 
                    previewInfo={previewInfo} 
                    setPreviewInfo={setPreviewInfo} 
                    setNext={setNext} 
                />
            </div>
        </div>
    );
};

export default PickettList;