import React, { useState, useEffect, useRef } from "react";
import "./MassUrlEntry.scss";
import LineEntry from "./LineEntry/LineEntry";
// import DraggableLineAbstraction from "./DraggableLineAbstraction/DraggableLineAbstraction";

const MassUrlEntry = ({ previewInfo, setPreviewInfo, setNext }) => {
  const [lineCount, setLineCount] = useState(1);
  const [focusInfo, setFocusInfo] = useState(null);
  // const [dragState, setDragState] = useState({
  //   dragging: false,
  //   dragIndex: null,
  //   dropIndex: null,
  // });
  const [displayOrder, setDisplayOrder] = useState([]);
  const containerRef = useRef(null);
  // Initialize and update display order when previewInfo changes
  useEffect(() => {
    if (previewInfo?.urls?.length > 0) {
      setLineCount(previewInfo.urls.length);
      setDisplayOrder([...Array(previewInfo.urls.length).keys()]);
    }
  }, [previewInfo?.urls?.length]);

  const handleValidEntry = (entryData) => {
    setPreviewInfo((prev) => {
      const newUrls = [...(prev.urls || [])];
      newUrls[entryData.index] = {
        mainUrl: entryData.url,
        description: entryData.description,
        altItems: entryData.alternatives,
      };
      return {
        ...prev,
        urls: newUrls,
      };
    });
    setNext(true);
  };

  const handleNewLine = (index, entryData) => {
    if (!entryData?.url?.trim()) return false;

    handleValidEntry(entryData);
    
    setPreviewInfo(prev => {
      const newUrls = [...(prev.urls || [])];
      newUrls.splice(index + 1, 0, {
        mainUrl: "",
        description: "",
        altItems: [],
      });
      return { ...prev, urls: newUrls };
    });
    
    setLineCount(prev => prev + 1);
    setFocusInfo({
      lineIndex: index + 1,
      inputType: "url",
    });
    return true;
  };

  const handleRemoveLine = (index, inputType) => {
    if (lineCount > 1) {
      setPreviewInfo((prev) => {
        const newUrls = [...prev.urls];
        newUrls.splice(index, 1);
        return {
          ...prev,
          urls: newUrls,
        };
      });
      setLineCount(prev => prev - 1);
      
      // Update display order when removing a line
      setDisplayOrder(prev => {
        const newOrder = prev.filter(i => i !== index);
        return newOrder.map(i => i > index ? i - 1 : i);
      });
      
      setFocusInfo({
        lineIndex: index - 1,
        inputType: inputType,
      });
    }
  };

  const handleNavigateToLine = (index, inputType) => {
    setFocusInfo({
      lineIndex: index,
      inputType: inputType || "url",
    });
  };

  // const handleDragStart = (index) => {
  //   setDragState({
  //     dragging: true,
  //     dragIndex: index,
  //     dropIndex: index
  //   });
  // };

  // const handleDragOver = (e) => {
  //   e.preventDefault();
    
  //   if (!dragState.dragging || !containerRef.current) return;

  //   const containerRect = containerRef.current.getBoundingClientRect();
  //   const mouseY = e.clientY - containerRect.top;
    
  //   const lineElements = Array.from(
  //     containerRef.current.getElementsByClassName('draggable-line-wrapper')
  //   );

  //   let closestIndex = dragState.dragIndex;
  //   let minDistance = Infinity;

  //   lineElements.forEach((element, index) => {
  //     const rect = element.getBoundingClientRect();
  //     const elementCenter = rect.top - containerRect.top + rect.height / 2;
  //     const distance = Math.abs(mouseY - elementCenter);
      
  //     if (distance < minDistance) {
  //       minDistance = distance;
  //       closestIndex = index;
  //     }
  //   });

  //   if (closestIndex !== dragState.dropIndex) {
  //     setDragState(prev => ({
  //       ...prev,
  //       dropIndex: closestIndex
  //     }));
  //   }
  // };

  // const handleDragEnd = () => {
  //   const { dragIndex, dropIndex } = dragState;
    
  //   if (dragIndex !== dropIndex && dragIndex !== null && dropIndex !== null) {
  //     setPreviewInfo(prev => {
  //       const newUrls = [...prev.urls];
  //       const [movedItem] = newUrls.splice(dragIndex, 1);
  //       newUrls.splice(dropIndex, 0, movedItem);
  //       return { ...prev, urls: newUrls };
  //     });
      
  //     // Update display order to match the new arrangement
  //     setDisplayOrder(prev => {
  //       const newOrder = [...prev];
  //       const [movedIndex] = newOrder.splice(dragIndex, 1);
  //       newOrder.splice(dropIndex, 0, movedIndex);
  //       return newOrder;
  //     });
  //   }

  //   setDragState({
  //     dragging: false,
  //     dragIndex: null,
  //     dropIndex: null
  //   });
  // };

  return (
    <div 
      ref={containerRef}
      className="mass-url-entry" 
      // onDragOver={handleDragOver}
    >
      {displayOrder.map((orderIndex, displayIndex) => {
        const urlData = previewInfo.urls[orderIndex];
        // const isDragging = dragState.dragIndex === displayIndex;
        // const showDropIndicator = dragState.dropIndex === displayIndex && dragState.dragging;
        
        return (
          // <DraggableLineAbstraction
          //   key={`${orderIndex}-${displayIndex}`}
          //   index={displayIndex}
          //   onDragStart={handleDragStart}
          //   onDragEnd={handleDragEnd}
          //   isDragging={isDragging}
          //   showDropIndicator={showDropIndicator}
          // >
            <LineEntry
              index={displayIndex}
              initialData={urlData}
              onValidEntry={handleValidEntry}
              onNewLine={handleNewLine}
              onRemoveLine={handleRemoveLine}
              onNavigateToLine={handleNavigateToLine}
              shouldFocus={focusInfo?.lineIndex === displayIndex}
              focusInput={focusInfo?.inputType}
              onFocusComplete={() => setFocusInfo(null)}
              isLastLine={displayIndex === previewInfo.urls.length - 1}
              hasMultipleLines={previewInfo.urls.length > 1}
              totalLines={previewInfo.urls.length}
              // dragHandleProps={{
              //   draggable: true,
              //   onDragStart: (e) => handleDragStart(displayIndex),
              //   onDragEnd: handleDragEnd
              // }}
            />
          // </DraggableLineAbstraction>
        );
      })}
    </div>
  );
};

export default MassUrlEntry;