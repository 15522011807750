import React, { useRef, useEffect } from 'react';

const HtmlCssRenderer = ({ htmlContent, cssContent}) => {
  const containerRef = useRef(null);

  useEffect(() => {
    if (containerRef.current) {
      const styleElement = document.createElement('style');
      // Encapsulate CSS by prefixing all rules with the container's class 
      const encapsulatedCss = cssContent.replace(/([^\r\n,{}]+)(,(?=[^}]*{)|\s*{)/g, `.html-css-renderer $1$2`);
      styleElement.textContent = encapsulatedCss;
      containerRef.current.appendChild(styleElement);

      return () => {
        if (styleElement.parentNode) {
          styleElement.parentNode.removeChild(styleElement);
        }
      };
    }
  }, [cssContent]);

  const containerStyle = {
    width: `100%`,
    overflow: 'auto',
    height: 'auto',
  };

  return (
    <div ref={containerRef} className="html-css-renderer" style={containerStyle}>
      <div dangerouslySetInnerHTML={{ __html: htmlContent }} />
    </div>
  );
};

export default HtmlCssRenderer;