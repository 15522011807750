import React, { useState, useEffect } from "react";
import "./CreateLine.scss";

// general components
import PercentHeader from "../../components/PercentCompletionHeader/PercentCompletionHeader";
import FlowComponent from "../../components/FlowComponent/FlowComponent";

// steps
import LinePreview from "../../components/LinePreview/LinePreview";
import TitleDesc from "../../components/LineCreationFlowElements/TitleDesc/TitleDesc";
import ColorOptions from "../../components/LineCreationFlowElements/ColorOptions/ColorOptions";
import LineBannerUpload from "../../components/LineCreationFlowElements/LineBannerUpload/LineBannerUpload";
import LineTagSelection from "../../components/LineCreationFlowElements/LineTagSelection/LineTagSelection";
import PickettList from "../../components/LineCreationFlowElements/PickettList/PickettList";

const CreateLine = () => {
  const [previewInfo, setPreviewInfo] = useState({
    background: "#FFFFFF",
    title: "",
    description: "",
    accent: "#EB675F",
    primary: "#EDEDED",
    lineBanner: null,
    tags: [],
    urls: [{
      mainUrl: '',
      description: '',
      altItems: []
    }]
  });
  const [currentStep, setCurrentStep] = useState(0);
  const [percentCompleted, setPercentCompleted] = useState(0);
  const [completedSteps, setCompletedSteps] = useState([false, false, false, false, false]);
  const exampleTags = [
    "React",
    "JavaScript",
    "CSS",
    "HTML",
    "Node.js",
    "Express",
    "MongoDB",
    "GraphQL",
  ];

  useEffect(() => {
    console.log(previewInfo);
  }, [previewInfo]);

  useEffect(() => {
    switch (currentStep) {
      case 0:
        if(!completedSteps[0]) {
        setPercentCompleted(0);
          setCompletedSteps([true, false, false, false, false]);
        }
        break;
      case 1:
        if(!completedSteps[1]) {
        setPercentCompleted(21);
        setCompletedSteps([true, true, false, false, false]);
        }
        break;
      case 2:
        if(!completedSteps[2]) {
        setPercentCompleted(45);
        setCompletedSteps([true, true, true, false, false]);
        }
        break;
      case 3:
        if(!completedSteps[3]) {
        setPercentCompleted(68);
        setCompletedSteps([true, true, true, true, false]);
        }
        break;
      case 4:
        if(!completedSteps[4]) {
        setPercentCompleted(94);
        
        setCompletedSteps([true, true, true, true, true]);
      }
        break
      default:
        // need dif behaviour for this
        alert("Invalid step");
        break;
    }
  }, [currentStep]);

  return (
    <div className="create-line">
      <PercentHeader
        mainText="Draw your"
        accentText="line."
        currentPercent={percentCompleted}
      />
      <div className="content">
        <LinePreview previewInfo={previewInfo} />
        <FlowComponent setCurrentStep={setCurrentStep}>
          <TitleDesc
            previewInfo={previewInfo}
            setPreviewInfo={setPreviewInfo}
            setNext={(isValid) => {
              // You might want to add additional logic here
              // to determine if the next button should be enabled
            }}
          />
          <ColorOptions
            previewInfo={previewInfo}
            setPreviewInfo={setPreviewInfo}
            setNext={() => {
              // Color selection is always valid, so we can
              // always enable the next button here
            }}
          />
          <LineBannerUpload
            previewInfo={previewInfo}
            setPreviewInfo={setPreviewInfo}
            setNext={(isValid) => {
              // This will be called when a file is selected or removed
            }}
          />
          <LineTagSelection
            previewInfo={previewInfo}
            setPreviewInfo={setPreviewInfo}
            tagOptions={exampleTags}
            setNext={(isValid) => {}}
          />
          <PickettList
            previewInfo={previewInfo}
            setPreviewInfo={setPreviewInfo}
            setNext={(isValid) => {}}
          />
        </FlowComponent>
      </div>
    </div>
  );
};

export default CreateLine;
