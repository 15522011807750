import React from "react";
import "./ListedLinePreview.css";
import { ReactComponent as ParticipantsIcon } from "../../resources/participantsIcon.svg";

function truncateText(text, maxLength) {
  if (text.length > maxLength) {
    return text.substring(0, maxLength).trim() + "...";
  } else {
    return text;
  }
}

// TODO: prob also need to pass in whatever kind of link to the actual line and/or code so that they can join from here/go to learn more page
const ListedLinePreview = ({ title, bio, participantCount, imageSource }) => {
  return (
    <div className="listed-line-wrapper">
      <div className="text-and-button-wrapper">
        <div className="text-wrapper">
          <div className="header-wrapper">
            <div className="searched-line-name">{title}</div>
            <div className="participants-wrapper">
              <ParticipantsIcon className="searched-line-participants-icon" />
              <div className="participants">
                {participantCount.toLocaleString()}
              </div>
            </div>
          </div>
          {/* tbh this truncating system is bad for scaleability, should be changed in the future. */}
          <p className="preview-bio">{truncateText(bio, 250)}</p>
        </div>
        <div className="button-wrapper">
          <button className="searched-join-button">Join</button>
          <button className="learn-more-button">Learn More</button>
        </div>
      </div>
      <img src={imageSource} alt="line-cover" className="searched-line-cover" />
    </div>
  );
};

export default ListedLinePreview;
