import React, { useState, useEffect } from 'react';
import LoginForm from '../../components/Forms/LoginForm/LoginForm';
import './Login.scss';

const Login = () => {
    return (
        <div className="login">
            <div className="left">
            </div>
            <div className="right">
                <LoginForm />
            </div>
        </div>
    );
}

export default Login;