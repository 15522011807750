import React, { useState, useEffect } from 'react';
import './ColorOptions.scss';
import CustomPallete from './CustomPallete/CustomPallete';
import { use } from 'bcrypt/promises';

const ColorOptions = ({previewInfo, setPreviewInfo, setNext}) => {

    useEffect(() => {
        setNext(true);
    }, []);

    const colorOptions=[
        {
            name:"background",
            type:"range",
            start : "#000000",
            end : "#FFFFFF",
        },
        {
            name:"text",
            type:"range",
            start : "#000000",
            end : "#FFFFFF",
        },
        {
            name:"primary",
            type:"range",
            start : "#000000",
            end : "#FFFFFF",
        },
        {
            name:"accent",
            type:"colors",
            colors:[
                "#EB675F",
                "#EBB35F",
                "#EBDD5F",
                "#8CEB5F",
                "#5F8FEB",
                "#625FEB",
                "#BE5FEB",
                "#EB5FCC",
                "#EB5F5F",
            ]
        }
    ];
    const handleColorChange = (color, type) => {
        setPreviewInfo(prev => ({...prev, [type]: color}));
    }
    return (
        <div className="color-options">
            <div className="custom-palettes">
                {
                    colorOptions.map((option, index) => (
                        <div 
                            className="color-option"
                            style={{ animationDelay: `${index * 0.2}s` }}
                        >
                            <CustomPallete key={index} options={option} onChange={handleColorChange}/>
                        </div>
                    ))
                }
                
            </div>
        </div>
    )
};

export default ColorOptions;