import React, { useEffect, useState, useRef } from 'react';
import { useNavigate, useLocation, Link } from 'react-router-dom';
import { useGoogleLogin } from '@react-oauth/google';
import { useAuth } from '../../../contexts/AuthContext';
import axios from 'axios';
import '../Forms.scss';
import googleLogo from '../../../resources/Google.svg';
import circleWarning from '../../../resources/circle-warning.svg';
import Logo from '../../Logo/Logo';

function RegisterForm({ email }) {
    const { isAuthenticated, googleLogin, login } = useAuth();
    let navigate = useNavigate();
    const [valid, setValid] = useState(false);
    const [showEmail, setShowEmail] = useState(email ? true : false);
    const [formData, setFormData] = useState({
        email:  email ? email : '',
        password: ''
    });
    const [sent, setSent] = useState(false);
    const [loadContent, setLoadContent] = useState(false);
    const [errorText, setErrorText] = useState("");
    const location = useLocation();
    const passwordRef = useRef(null);

    useEffect(() => {
        let checkRefUntil;
        if (email){
            checkRefUntil = setInterval(() => {
                if (passwordRef.current){
                    clearInterval(checkRefUntil);
                    passwordRef.current.focus();
                }
            }, 100);
        }
        return () => clearInterval(checkRefUntil);
    },[email, passwordRef.current]);

    useEffect(() => {
        async function googleLog(code) {
            try {
                const codeResponse = await googleLogin(code, true);
                console.log("codeResponse: " + codeResponse);
            } catch (error) {
                if (error.response.status === 409) {
                    failed("Email already exists");
                } else {
                    console.error("Google login failed:", error);
                    failed("Google login failed. Please try again");
                }
            }
        }
        const queryParams = new URLSearchParams(location.search);
        const code = queryParams.get('code');
        if (code) {
            setLoadContent(false);
            if (!sent) {
                googleLog(code);
            }
            setSent(true);
        } else {
            setLoadContent(true);
        }
    }, [location, googleLogin, sent]);

    useEffect(() => {
        if (isAuthenticated && isAuthenticated !== null) {
            navigate('/');
        }
    }, [isAuthenticated, navigate]);

    useEffect(() => {
        if (formData.email !== '' && formData.password !== '') {
            setValid(true);
        } else {
            setValid(false);
        }
    }, [formData.email, formData.password]);

    const handleChange = (e) => {
        setFormData({ ...formData, [e.target.name]: e.target.value });
    }

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            const response = await axios.post('/api/verify-email', { email: formData.email });
            if (response.data.data && response.data.data.result === "undeliverable") {
                setErrorText("Invalid Email. Please try again");
                return;
            }
        } catch (error) {
            console.error("Email verification failed:", error);
            setErrorText("Invalid Username/Email or Password. Please try again");
            return;
        }    
        try {
            const response = await axios.post('/api/register', formData);
            await login(formData);
            navigate('/');
        } catch (error) {
            if (error.response.status === 400) {
                setErrorText("Username or Email already exists");
            } else {
                setErrorText(error.response.data.message);
            }
        }
    }

    const google = useGoogleLogin({
        onSuccess: () => { console.log("succeeded") },
        flow: 'auth-code',
        ux_mode: 'redirect',
        onFailure: () => { failed("Google login failed. Please try again") },
    });

    function failed(message) {
        navigate('/login');
        setErrorText(message);
    }

    if (!loadContent) {
        return ("");
    }

    return (
        <form onSubmit={handleSubmit} className='form'>
            {errorText !== "" && 
                <div className="error">
                    <img src={circleWarning} alt="error" />
                    {errorText}
                </div>
            }
            <div className="logo-login">
                <Logo size={30} />
                <h1>Pickett</h1>
            </div>
            <button type="button" className="button google" onClick={() => google()}>
                Continue with Google<img src={googleLogo} alt="google" />
            </button>
            <div className="divider">
                <hr />
                <p>or</p>
                <hr />
            </div>
            <div className="email-form">
                <div className={`button active show ${showEmail ? "invisible":""}`} onClick={()=>{setShowEmail(true)}}>
                    Register with Email
                    <p className="already">Already have an account? <Link to="/login" >Login</Link></p>    
                </div>
                <div className={`form ${showEmail ? "visible" : ""}`}>
                    <div className="email">
                        <p>Email</p>
                        <input type="text" name="email" value={formData.email} onChange={handleChange} placeholder="Valid email..." required />
                    </div>
                    <div className="password">
                        <p>Password</p>
                        <input type="password" name="password" value={formData.password} onChange={handleChange} placeholder="Enter your password..." required ref={passwordRef}/>
                    </div>
                    <button type="submit" className={`button ${valid ? "active":""}`}>Register</button>
                    <p className="already">Already have an account? <Link to="/login" >Log in</Link></p>
                </div>
            </div>
        </form>
    );
}

export default RegisterForm;
