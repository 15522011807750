import React, { useState, useEffect } from 'react';
import './LineBannerUpload.scss';

import FileUpload from '../../FileUpload/FileUpload'; 
import Label from '../../Label/Label'; 

const LineBannerUpload = ({ previewInfo, setPreviewInfo, setNext }) => {
    const [hasValidImage, setHasValidImage] = useState(false);

    const handleFileChange = (file) => {
        if (file) {
            setHasValidImage(true);
            setNext(true);
            // Update previewInfo if needed
            setPreviewInfo(prevInfo => ({ ...prevInfo, lineBanner: file }));
        } else {
            setHasValidImage(false);
            setNext(false);
            // Remove lineBanner from previewInfo if needed
            setPreviewInfo(prevInfo => {
                const { lineBanner, ...rest } = prevInfo;
                return rest;
            });
        }
    };

    // onload
    useEffect(() => {
        if (previewInfo.lineBanner !== null) {
            setHasValidImage(true);
            setNext(true);
        } else {
            setHasValidImage(false);
            setNext(false);
        }
    }, []);

    return (
        <div className="line-banner-upload">
            <Label label="Line Banner">
                <FileUpload 
                    classroomName="line" 
                    onFileChange={handleFileChange}
                    currentFile={previewInfo?.lineBanner}
                />
            </Label>
        </div>
    );
};

export default LineBannerUpload;