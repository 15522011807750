import React, { useState, useEffect } from 'react';
import './TagSelector.scss';

const TagSelector = ({ tags, selectionMax, onChange, initialSelection = [] }) => {
  const [selectedTags, setSelectedTags] = useState(initialSelection);

  // Handle initial selection on mount
  useEffect(() => {
    if (initialSelection.length > 0) {
      setSelectedTags(initialSelection);
      onChange(initialSelection);
    }
  }, []);

  // Handle external changes to initialSelection
  useEffect(() => {
    if (JSON.stringify(initialSelection) !== JSON.stringify(selectedTags)) {
      setSelectedTags(initialSelection);
      onChange(initialSelection);
    }
  }, [initialSelection]);

  const handleTagClick = (tag) => {
    if (selectedTags.includes(tag)) {
      const newSelection = selectedTags.filter((t) => t !== tag);
      setSelectedTags(newSelection);
      onChange(newSelection);
    } else if (selectionMax === 0 || selectedTags.length < selectionMax) {
      const newSelection = [...selectedTags, tag];
      setSelectedTags(newSelection);
      onChange(newSelection);
    }
  };

  return (
    <div className="tag-selector-container">
      {tags.map((tag) => (
        <div
          key={tag}
          className={`tag-item ${selectedTags.includes(tag) ? 'selected' : ''} ${
            selectionMax !== 0 && selectedTags.length >= selectionMax && !selectedTags.includes(tag)
              ? 'disabled'
              : ''
          }`}
          onClick={() => handleTagClick(tag)}
        >
          <span>{tag}</span>
          {selectedTags.includes(tag) && (
            <span className="remove-icon">×</span>
          )}
        </div>
      ))}
    </div>
  );
};

export default TagSelector;