import React, { useState, useEffect } from "react";
import { ReactComponent as GroupsIcon } from "../../resources/participantsIcon.svg";
import Label from "./../Label/Label";
import "./PinnedLinePreviewer.scss";

function truncateText(text, maxLength) {
  if (text.length > maxLength) {
    return text.substring(0, maxLength).trim() + "...";
  } else {
    return text;
  }
}

function PinnedLinePreviewer({
  title,
  coverPic,
  bio,
  owner,
  ownerPFP,
  participants,
  site,
  onChange,
}) {
  const [currentTitle, setCurrentTitle] = useState("");
  const [currentCoverPic, setCurrentCoverPic] = useState("");
  const [currentBio, setCurrentBio] = useState("");
  const [currentOwner, setCurrentOwner] = useState("");
  const [currentParticipants, setCurrentParticipants] = useState("");
  const [currentsite, setCurrentsite] = useState("");
  const [currentOwnerPFP, setCurrentOwnerPFP] = useState("");

  useEffect(() => {
    setCurrentTitle(title);
    setCurrentCoverPic(coverPic);
    setCurrentBio(bio);
    setCurrentOwner(owner);
    setCurrentParticipants(participants);
    setCurrentsite(site);
    setCurrentOwnerPFP(ownerPFP);
  }, [title, coverPic, bio, owner, ownerPFP, participants, site]);

  const [width, setWidth] = useState(window.innerWidth);
  useEffect(() => {
    const handleResize = () => setWidth(window.innerWidth);
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return (
    <Label label="pinned" className="pinned-line-previewer-container">
      <div className="pinned-line-previewer">
        <div className="pinned-line-alignment">
          <img
            className="line-thumb-nail"
            src={currentCoverPic}
            alt={`${currentTitle} cover image`}
          />
          <span className="heading-line">
            <h2 className="preview-title">{currentTitle}</h2>
          
          <span className="line-participant-count">
            <GroupsIcon className="participants-icon" />
            {currentParticipants}
          </span>
          </span>
          <p className="preview-bio">{truncateText(currentBio, (width >= 500 ? 400 : 200))}</p>
          <div className="line-owner-tag-container">
            <span className="line-owner-tag">
              <img
                className="line-owner-pfp"
                src={currentOwnerPFP}
                alt={`${currentOwner} profile picture`}
              />
              {currentOwner}
            </span>
          </div>
        </div>
      </div>
    </Label>
  );
}

export default PinnedLinePreviewer;