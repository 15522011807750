import React, { useState, useEffect } from 'react';
import "./SearchFilterSelector.scss";
import Label from "../Label/Label";
import Switch from "../Switch/Switch";
import SearchBar from "../SearchBar/SearchBar";
import MultiSelector from "../MultiSelector/MultiSelector";
import TagSelector from "../TagSelector/TagSelector";

const handleTypeChange = (newState) => {
    console.log(newState);
    // Change selected type for search, should update a variable to be sent on search
};

const handleScaleChange = (newState) => {
    console.log(newState);
    // Change selected scale for search, should update a variable to be sent on search
};

const handleParticipantsChange = (newState) => {
    console.log(newState);
    // Change selected participants for search, should update a variable to be sent on search
};

const handleRangeStartChange = (newState) => {
    console.log(newState);
};

const handleRangeEndChange = (newState) => {
    console.log(newState);
};

const handleTagChange = (newState) => {
    console.log(newState);
};

const handleSearch = (searchValue) => {
    // replace with real search function
    alert(searchValue);
};

const SearchFilterSelector = () => {
    const initialDate = new Date('2023-08-20');
    const tags = ['React', 'JavaScript', 'CSS', 'HTML', 'Node.js', 'Express', 'MongoDB', 'GraphQL'];

    const [selectedItems, setSelectedItems] = useState({});
    const [visibleItems, setVisibleItems] = useState({});

    useEffect(() => {
        const timer = setTimeout(() => {
            setVisibleItems(selectedItems);
        }, 50); // Small delay for the animation to kick in

        return () => clearTimeout(timer);
    }, [selectedItems]);

    const handleSelectorChange = (newState) => {
        setSelectedItems(newState);
        console.log(newState);
        // Change states for each filter
    };

    return (
        <div className='search-filter-selector-container'>
            <SearchBar onSearch={handleSearch}/>
            <div className='temp-spacer'></div>

            <MultiSelector
                items={[
                    'type',
                    'scale',
                    'participants',
                    'range',
                    'tags'
                ]}
                selectedItems={selectedItems}
                setSelectedItems={handleSelectorChange}
            />
            <div className='temp-spacer'></div>

            <div className={`filter-item ${visibleItems.type && 'show'}`}>
                <Label className="type-selector-label" label="type">
                    <Switch
                        options={["all", "urgent", "current", "ended"]}
                        onChange={handleTypeChange}
                    />
                </Label>
            </div>

            <div className='temp-spacer'></div>

            <div className={`filter-item ${visibleItems.scale && 'show'}`}>
                <Label className="scale-selector-label" label="scale">
                    <Switch
                        options={["city", "state", "country", "global"]}
                        onChange={handleScaleChange}
                    />
                </Label>
            </div>

            <div className='temp-spacer'></div>

            <div className={`filter-item ${visibleItems.participants && 'show'}`}>
                <Label className="scale-selector-label" label="participants">
                    <Switch
                        options={["more", "less"]}
                        onChange={handleParticipantsChange}
                    />
                    <input className="participant-threshold-input" type='number'></input>
                </Label>
            </div>

            <div className='temp-spacer'></div>

            <div className={`filter-item ${visibleItems.range && 'show'}`}>
                <Label className="range-input" label="range">
                    <div className="date-selector-container">
                        <input className="search-year-range" type="date" onChange={handleRangeStartChange} />
                        <input className="search-year-range" type="date" onChange={handleRangeEndChange} />
                    </div>
                </Label>
            </div>

            <div className='temp-spacer'></div>

            <div className={`filter-item ${visibleItems.tags && 'show'}`}>
                <Label className="tag-selector-label" label="tags">
                    <TagSelector
                        tags={tags}
                        selectionMax={3}
                        onChange={handleTagChange}
                    />
                </Label>
            </div>
        </div>
    );
};

export default SearchFilterSelector;
