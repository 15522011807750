import React from 'react';
import './NumberScroller.scss';

let scrollerCounter = 0; //counter for unique ids

const NumberScroller = ({
  number,
  startAnimation = false,
  delayPerDigit = 0.2,
  durationPerDigit = 1,
}) => {
  const digits = String(number).split('');
  const numDigits = digits.length;

  //u need to do this or else scrollers on the same page will fw each ohter
  const scrollerId = React.useMemo(() => {
    scrollerCounter += 1;
    return scrollerCounter;
  }, []);

  return (
    <div className="number-scroller">
      {digits.map((digit, index) => (
        <DigitScroller
          key={index}
          digit={digit}
          index={index}
          scrollerId={scrollerId}
          delay={(numDigits - index - 1) * delayPerDigit}
          duration={durationPerDigit}
          startAnimation={startAnimation}
        />
      ))}
    </div>
  );
};

const DigitScroller = ({
  digit,
  index,
  scrollerId,
  delay,
  duration,
  startAnimation,
}) => {
  const [animationKey, setAnimationKey] = React.useState(0);
  const animationName = `scroll-${scrollerId}-${index}-${animationKey}`;
  const targetDigit = parseInt(digit, 10);
  const loops = 1; //num of times to loop through 0-9
  const digitSequence = [];

  for (let i = 0; i < loops * 10 + targetDigit + 1; i++) {
    digitSequence.push(i % 10);
  }

  const totalDigits = digitSequence.length;
  const keyframes = `
    @keyframes ${animationName} {
      0% { transform: translateY(0%); }
      100% { transform: translateY(-${((totalDigits - 1) / totalDigits) * 100}%); }
    }
  `;

  //restart animation when startAnimation true
  React.useEffect(() => {
    if (startAnimation) {
      setAnimationKey((prev) => prev + 1);
    }
  }, [startAnimation]);

  return (
    <div className="digit-scroller">
      <style>{keyframes}</style>
      <div
        className="digit-scroller-inner"
        style={{
          animationName: startAnimation ? animationName : 'none',
          animationDelay: `${delay}s`,
          animationDuration: `${duration}s`,
          animationTimingFunction: 'ease-out',
          animationFillMode: 'forwards',
        }}
      >
        {digitSequence.map((d, idx) => (
          <div key={idx} className="digit">
            {d}
          </div>
        ))}
      </div>
    </div>
  );
};

export default NumberScroller;
