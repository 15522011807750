import React from 'react';
import './AnimatedCheckToX.scss';

const AnimatedCheckToX = ({ size, className, isHovered, defaultColor = "--background-color", hoverColor = "--text-color" }) => {
    return (
        <div 
            className={`animated-check-container ${className} ${isHovered ? 'hovered' : ''}`} 
            style={{ fontSize: size }}
        >
            <div 
                className="check"
                style={{ 
                    '--default-color': defaultColor, 
                    '--hover-color': hoverColor 
                }}
            ></div>
        </div>
    );
};

export default AnimatedCheckToX;