import React, { useState, useEffect } from "react";
import "./LineViewer.scss";
import { ReactComponent as GroupsIcon } from "../../resources/participantsIcon.svg";
import { useNavigate } from "react-router-dom";
import { useData } from "../../contexts/DataContext";
import Label from "./../Label/Label";

const LineViewer = ({ title = "picketts", lines }) => {
    const navigate = useNavigate();
    const [lineViewerItems, setLineViewerItems] = useState([]);
    const [hovered, setHovered] = useState(0);

    useEffect(() => {
        let fetchedLines;
        fetchedLines = lines;
        const linesWithSelection = fetchedLines.map((line) => ({
            ...line,
            selected: false,
        }));
        setLineViewerItems(linesWithSelection);
    }, [lines]);

    const handleContentClick = (name) => {
        navigate(`/line/${name}`);
    };

    const handleContentMouseEnter = (id) => {
        setHovered(id);
    };

    const handleContentMouseLeave = () => {
        setHovered(0);
    };

    return (
        <Label label={title} className="line-viewer-container">
            {lineViewerItems.map((item) => (
                <div key={item.id} className="line-viewer-item">
                    <div
                        className="line-viewer-content"
                        onClick={() => handleContentClick(item.name)}
                        onMouseEnter={() => handleContentMouseEnter(item.id)}
                        onMouseLeave={handleContentMouseLeave}
                    >
                        <span
                            className={`line-viewer-item-name ${item.id === hovered ? "line-viewer-red-text" : ""
                                }`}
                        >
                            {item.name}
                        </span>
                        <span
                            className={`line-viewer-item-participants ${item.id === hovered ? "line-viewer-red-text" : ""
                                }`}
                        >
                            <GroupsIcon
                                className={`line-viewer-participants-icon ${item.id === hovered ? "line-viewer-red-icon" : ""
                                    }`}
                            />
                            {item.participants.toLocaleString()}
                        </span>
                    </div>
                </div>
            ))}
        </Label>
    );
};

export default LineViewer;