import React from 'react';
import { useEffect } from 'react';
import './NewUserLineSelection.scss';

import LineSelector from "../../LineSelector/LineSelector";

const NewUserLineSelection = ({previewInfo, setPreviewInfo, setNext}) => {

    useEffect(() => {
        setNext(true);
    }, []);

    return (
        <div className="line-selector-bound">
            <LineSelector previewInfo={previewInfo} setPreviewInfo={setPreviewInfo} hasJoinButton={false}/>
        </div>
    );
};

export default NewUserLineSelection;