import React, { useState, useEffect } from 'react';
import TextEditor from '../../TextEditor/TextEditor';

import LinePreview from '../../../resources/LinePreview.svg';

import './TitleDesc.scss';
// const Delta = Quill.import('delta');


const TitleDesc = ({ previewInfo, setPreviewInfo, setNext }) => {
    const [range, setRange] = useState();
    const [lastChange, setLastChange] = useState();
    const [readOnly, setReadOnly] = useState(false);
    const quillRef = React.createRef();

    const [title, setTitle] = useState('');
    const [titleDone, setTitleDone] = useState(false);

    const [content, setContent] = useState('');

    useState(() => {
        if(previewInfo){
            setTitle(previewInfo.title);
            setContent(previewInfo.description);
        }
    }, []);

    const handleTitleChange = (e) => {
        setTitle(e.target.value);
        setPreviewInfo(prev => ({...prev, title: e.target.value}));
    }

    useEffect(() => {
        setPreviewInfo(prev => ({...prev, description: lastChange}));
        console.log("hello");
    }, [lastChange]);

    useEffect(() => {   
        if(title !== '' && content !== '') {
            setNext(true);
        } else {
            setNext(false);
        }
    }
    , [title, content]);



    const handleBlur = () => {
        if(title !== '') {
            setTitleDone(true);
        } else {
            setTitleDone(false);
        }
    }

    return (
        <div className="title-desc">
            <div className="info">
                <div className="title">
                    <p className="header">Title</p>
                    <div className={`${titleDone && "done"} input-wrapper`}>
                        <input type="text"  value={title} onChange={handleTitleChange} onBlur={handleBlur} />
                    </div>
                </div>
                <div className="description">
                    <p className="header">Description</p>
                    <TextEditor
                        readOnly={readOnly}
                        // defaultValue={}
                        onSelectionChange={setRange}
                        onTextChange={setLastChange}
                        setContent={setContent}
                        content={content}
                    />
                </div>
            </div>
 
        </div>
    )
};

export default TitleDesc;