import mockData from './mockdata.json';

const mockFetch = (config) => {
    const { method = 'get', url, data: body, params = {}, headers = {} } = config;

    return new Promise((resolve, reject) => {
        setTimeout(() => {
            //axios params behavior
            const queryString = new URLSearchParams(params).toString();
            const fullUrl = queryString ? `${url}?${queryString}` : url;

            const urlObj = new URL(fullUrl, 'http://localhost');
            const path = urlObj.pathname;
            const queryParams = {};
            urlObj.searchParams.forEach((value, key) => {
                queryParams[key] = value;
            });

            const isEqual = (obj1, obj2) => {
                return JSON.stringify(obj1) === JSON.stringify(obj2);
            };

            //find a matching entry in mockData
            const match = mockData.find((entry) => {
                // Match method
                if (entry.method.toLowerCase() !== method.toLowerCase()) {
                    return false;
                }
                //match path
                if (entry.url !== path) {
                    return false;
                }
                //match params
                if (!isEqual(entry.params || {}, queryParams)) {
                    return false;
                }
                //match body (for methods that support it)
                if (['post', 'put', 'patch', 'delete'].includes(method.toLowerCase())) {
                    if (!isEqual(entry.body || {}, body || {})) {
                        return false;
                    }
                }
                return true;
            });

            if (match) {
                resolve({
                    data: match.response,
                    status: 200,
                    statusText: 'OK',
                    headers: {},
                    config,
                });
            } else {
                //sumulated 404
                reject({
                    response: {
                        data: { success: false, message: 'Not Found' },
                        status: 404,
                        statusText: 'Not Found',
                        headers: {},
                        config,
                    },
                });
            }
        }, 500); //can adjust latency
    });
};

export default mockFetch;
