function hexToRgb(hex) {
    hex = hex.replace(/^#/, '');
    let bigint = parseInt(hex, 16);
    let r = (bigint >> 16) & 255;
    let g = (bigint >> 8) & 255;
    let b = bigint & 255;

    return [r, g, b];
}

function rgbToHex(r, g, b) {
    return "#" + ((1 << 24) + (r << 16) + (g << 8) + b).toString(16).slice(1);
}

function generateGradient(hexStart, hexEnd, steps) {
    const startRGB = hexToRgb(hexStart);
    const endRGB = hexToRgb(hexEnd);
    const gradient = [];

    // Calculate the incremental change for each channel (r, g, b)
    const stepR = (endRGB[0] - startRGB[0]) / (steps + 1);
    const stepG = (endRGB[1] - startRGB[1]) / (steps + 1);
    const stepB = (endRGB[2] - startRGB[2]) / (steps + 1);

    // Generate intermediate colors
    for (let i = 0; i <= steps + 1; i++) {
        const r = Math.round(startRGB[0] + stepR * i);
        const g = Math.round(startRGB[1] + stepG * i);
        const b = Math.round(startRGB[2] + stepB * i);
        gradient.push(rgbToHex(r, g, b));
    }

    return gradient;
}

export { generateGradient, hexToRgb, rgbToHex };