import React from 'react';
import './ProfilePreview.scss';

const ProfilePreview = ({ imageSrc, name, description }) => {
  return (
    <div className="profile-preview">
      <div className="profile-image-container">
        {imageSrc ? (
          <img 
            src={imageSrc} 
            alt={name} 
            className="profile-image"
          />
        ) : (
          <div className="profile-image-placeholder" />
        )}
      </div>
      <div className="profile-info">
        <h2 className="profile-name">{name}</h2>
        <p className="profile-description">{description}</p>
      </div>
    </div>
  );
};

export default ProfilePreview;