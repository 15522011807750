import React from 'react';
import './Pickett.scss';

function Pickett({ pickett }) {
    return (
        <div className="pickett">
            <p>{pickett.name}</p>
            <p>{pickett.organizer}</p>
        </div>
    );
}

export default Pickett;