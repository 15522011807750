import React from 'react';
import './FancyProgressBar.scss';

// Potential UPGRADE: % displayed in middle red bar on hover || tool tip?

const CurrentBox = ({ value, width, subheading }) => (
  <div className="box current-box" style={{ width }}>
    <div className="value">{value}</div>
    <div className="subheading">{subheading}</div>
  </div>
);

const ProgressBox = ({ width, isFilled }) => (
  <div className={`box progress-box ${isFilled ? 'filled' : ''}`} style={{ width }} />
);

const TargetBox = ({ value, width, subheading }) => (
  <div className="box target-box" style={{ width }}>
    <div className="value">{value}</div>
    <div className="subheading">{subheading}</div>
  </div>
);

const FancyProgressBar = ({ currentParticipants, targetValue, subdivisions, maxWidth, currentSubheading, targetSubheading }) => {
  const boxWidth = 60;
  const marginWidth = 10;
  const totalWidth = maxWidth;
  
  // Calculate the number of margins needed (one less than the total number of boxes)
  const totalMargins = subdivisions + 1;
  
  // Calculate the total width taken by margins
  const totalMarginWidth = (totalMargins) * marginWidth;
  
  // Calculate the remaining width for the boxes
  const remainingWidth = totalWidth - totalMarginWidth;
  
  // Calculate the width of each progress/remaining box
  const progressBoxWidth = (remainingWidth - (2 * boxWidth)) / subdivisions;
  
  const progress = currentParticipants / targetValue;
  const filledSubdivisions = Math.floor(progress * subdivisions);
  const isProgressBoxFilled = progress >= 0.2;
  
  const filledProgressWidth = isProgressBoxFilled
    ? progressBoxWidth * filledSubdivisions
    : 0;

  const remainingBoxStyle = {
    width: `${progressBoxWidth}px`,
  };

  return (
    <div className="fancy-progress-bar" style={{ width: `${totalWidth}px` }}>
      <CurrentBox value={currentParticipants} width={`${boxWidth}px`} subheading={currentSubheading} />
      <ProgressBox width={`${filledProgressWidth}px`} isFilled={isProgressBoxFilled} />
      {Array.from({ length: subdivisions - filledSubdivisions }).map((_, index) => (
        <div key={index} className="box remaining-box" style={remainingBoxStyle} />
      ))}
      <TargetBox value={targetValue} width={`${boxWidth}px`} subheading={targetSubheading} />
    </div>
  );
};

export default FancyProgressBar;