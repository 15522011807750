import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';

function RedirectTest() {
    const navigate = useNavigate();
    const [inputValue, setInputValue] = useState('');
    
    const handleSubmit = (e) => {
        e.preventDefault();
        const jsonValue = JSON.stringify({ site: inputValue });
        const encodedValue = encodeURIComponent(jsonValue);
        navigate(`/redirect?data=${encodedValue}`);
    }

    return (
        <div className="redirect-test">
            <h1>Redirect Test</h1>
            <form onSubmit={handleSubmit}>
                <input
                type="text"
                value={inputValue}
                onChange={(e) => setInputValue(e.target.value)}
                placeholder="Enter a website"
                required
                />
                <button type="submit">Submit</button>
        </form>
        </div>
    );
}

export default RedirectTest;