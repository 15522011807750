import React, { useState } from 'react';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import './TextEditor.scss';

const modules = {
    toolbar: [
        // [{ header: '1' }, { header: '2' }],
        ['bold', 'italic', 'underline', 'strike'],
        [{ list: 'ordered' }, { list: 'bullet' }],
        ['link', 'image'],
        [{ align: [] }],
        ['clean'],
    ],
};

const TextEditor = ({ content, setContent, onTextChange }) => {

    const handleChange = (value) => {
        setContent(value);
        onTextChange(value);
    };

    return (
        <div className="text-editor">
            <ReactQuill value={content} onChange={handleChange} modules={modules} />
            {/* <div>
                <h3>Editor Content:</h3>
            <div dangerouslySetInnerHTML={{ __html: content }}></div>
            </div> */}
        </div>
    );
};

export default TextEditor;
