import React, { useState } from 'react';

import './Logo.scss';

function Logo({size}){
    const [animation, setAnimation] = useState(false);
    setTimeout(() => {
        setAnimation(true);

    }, 100);

    const rerunAnimation = (event) => {
        setAnimation(false);
    };
    
    return (
        <div className="logo" style={{width: `${size}px`, height:`${size}px`}} onClick={rerunAnimation} >
            <div className="logo-container">
                {animation && <div className="diagonal-line"></div>}
            </div>
        </div>
    );
}

export default Logo;