import React, { useState, useEffect } from 'react';
import './CustomPallete.scss';
import { generateGradient, rgbToHex } from './PalleteHelpers';

const CustomPallete = ({options, onChange}) => {
    const [colors, setColors] = useState([]);
    const [chosenColor, setChosenColor] = useState(0);
    useEffect(() => {
        if(options.type === "range"){
            setColors(generateGradient(options.start, options.end, 7));
        } else{
            setColors(options.colors);
        }
    }, [options]);

    const handleChange= (index)=>{
        setChosenColor(index);
        onChange(colors[index], options.name);
    }


    // const colors = generateGradient("#000000", "#FFFFFF", 7);
    return (
        <div className="custom-palette">
            <div className="title">

                <div className="name child">
                    {options.name}
                </div>
                <div className="hex child">{colors[chosenColor]}</div>
            </div>
            <div className="colors child">
                {
                    colors.map((color, index) => (
                        <div
                            key={index}
                            onClick={()=>handleChange(index)}
                            className="color"
                            style={{ backgroundColor: color }}
                        >
                            {index === chosenColor && <div className="diagonal-line"></div>}

                        </div>
                    ))
                }
            </div>
        </div>
    )
}

export default CustomPallete;