import React from "react";
import "./MobileUserStatDisplay.scss";
import Label from "../../Label/Label";
import "../../../css/colors.css";

function MobileUserStatDisplay({
  followers,
  following,
  lines,
  donations,
  streak,
  badges,
}) {
  const getStreakColor = (streak) => {
    const streakColors = {
      365: "var(--accent-ice-blue)",
      183: "var(--accent-color)",
      31: "var(--accent-orange)",
      7: "var(--accent-yellow)",
    };

    for (const [key, value] of Object.entries(streakColors).reverse()) {
      if (streak >= key) return value;
    }

    return "var(--light-bounding-color)";
  };

  return (
    <div className="mobile-profile-stat-box">
      <div className="mobile-stat-row">
        <Label label="stats">
          <div className="mobile-stat-view">
            {/* <div className="mobile-stat-grid"> */}
              <StatItem value={followers} label="followers" />
              <StatItem value={following} label="following" />
              <StatItem value={lines} label="lines" />
              <StatItem value={donations} label="donations" />
            {/* </div> */}
          </div>
        </Label>
      </div>
      <div className="mobile-streak-badge-row">
        <div className="mobile-streak-view">
          <span className="mobile-streak-value-col">
            <h1
              className="mobile-streak-number"
              style={{ color: getStreakColor(streak) }}
            >
              {streak}
            </h1>
            <h4
              className="mobile-streak-sub-heading"
              style={{ color: getStreakColor(streak) }}
            >
              streak
            </h4>
          </span>
        </div>
        <div className="mobile-badges-label-bounding">
          <Label label="badges">
            <div className="mobile-badge-view">{badges}</div>
          </Label>
        </div>
      </div>
    </div>
  );
}

function StatItem({ value, label }) {
  return (
    <div className="mobile-stat-item">
      <h1 className="mobile-profile-stat-main">{value}</h1>
      <h4 className="mobile-profile-stat-sub-header">{label}</h4>
    </div>
  );
}

export default MobileUserStatDisplay;