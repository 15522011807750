import React, { useState, useEffect } from 'react';
import './NewUserSetup.scss';
import FlowComponent from '../../components/FlowComponent/FlowComponent';
import Logo from '../../components/Logo/Logo';
import PercentHeader from '../../components/PercentCompletionHeader/PercentCompletionHeader';

import BasicInfo from '../../components/NewUserSetupFlowElements/BasicInfo/BasicInfo';
import LineSelection from '../../components/NewUserSetupFlowElements/NewUserLineSelection/NewUserLineSelection';
import ChromeExtensionOutlink from '../../components/NewUserSetupFlowElements/ChromeExtensionOutlink/ChromeExtensionOutlink';

const NewUserSetup = () => {
    const [previewInfo, setPreviewInfo] = useState({
        background: '#FFFFFF', 
        title: '', 
        description: '', 
        accent: "#EB675F", 
        primary: "#EDEDED",
        initialLine: [],
    });
    
    const [currentStep, setCurrentStep] = useState(0);
    const [percentCompleted, setPercentCompleted] = useState(0);
    const [completedSteps, setCompletedSteps] = useState([false, false, false]);

    useEffect(() => {
        console.log(previewInfo);
    }, [previewInfo]);

    useEffect(() => {
        switch (currentStep) {
            case 0:
                if (!completedSteps[0]) {
                    setPercentCompleted(0);
                    setCompletedSteps([true, false, false]);
                }
                break;
            case 1:
                if (!completedSteps[1]) {
                    setPercentCompleted(50);
                    setCompletedSteps([true, true, false]);
                }
                break;
            case 2:
                if (!completedSteps[2]) {
                    setPercentCompleted(100);
                    setCompletedSteps([true, true, true]);
                }
                break;
            default:
                alert("Invalid step");
                break;
        }
    }, [currentStep]);

    return (
        <div className="new-user-setup">
            <div className="join-header">
                <PercentHeader
                    mainText="Join the"
                    accentText="line."
                    currentPercent={percentCompleted}
                />
            </div>
            <div className="content">
                <FlowComponent setCurrentStep={setCurrentStep}>
                    <BasicInfo 
                        previewInfo={previewInfo} 
                        setPreviewInfo={setPreviewInfo}
                        setNext={(isValid) => {
                            // Add validation logic here
                        }}
                    />
                    <LineSelection 
                        previewInfo={previewInfo} 
                        setPreviewInfo={setPreviewInfo}
                        setNext={(isValid) => {
                        }}
                    />
                    <ChromeExtensionOutlink 
                        previewInfo={previewInfo} 
                        setPreviewInfo={setPreviewInfo}
                        setNext={(isValid) => {
                            // Add validation logic here
                        }}
                    />
                </FlowComponent>
            </div>
        </div>
    );
};

export default NewUserSetup;