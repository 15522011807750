import React, { useEffect, useState } from 'react';
import './LineTagSelection.scss';

import TagSelector from '../../TagSelector/TagSelector';
import Label from '../../Label/Label';

const LineTagSelection = ({previewInfo, setPreviewInfo, tagOptions, setNext}) => {
    const [selectedCount, setSelectedCount] = useState(0);
    
    // Check initial state on mount
    useEffect(() => {
        if (previewInfo?.tags?.length > 0) {
            setSelectedCount(previewInfo.tags.length);
            setNext(true);
        }
    }, []);
    
    const handleTagUpdate = (selectedTags) => {
        setSelectedCount(selectedTags.length);
        setPreviewInfo(prev => ({...prev, tags: selectedTags}));
        setNext(selectedTags.length >= 1);
    };

    return (
        <div className="tag-selection">
            <Label label="Select Tags" >
            <TagSelector 
                tags={tagOptions}
                selectionMax={5}
                onChange={handleTagUpdate}
                initialSelection={previewInfo?.tags || []}
            />
            </Label>
        </div>
    );
};

export default LineTagSelection;