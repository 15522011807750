import React, { useState, useRef, useEffect } from "react";
import "./MobileSwitch.scss";
import "../Switch.scss";

const MobileSwitch = ({ options, onChange, hPadding=12, vPadding=5, bRadius=13, fSize=16, mFSize=13, toggle, setToggle, initialSelected = 0}) => {
    const [selected, setSelected] = useState(0);
    const containerRef = useRef(null);
    const optionRefs = useRef([]);

    useEffect(() => {
        setSelected(initialSelected);
    }, [initialSelected]);

    useEffect(() => {
        console.log("mounted");
    }, []);

    useEffect(() => {
        updateHighlight(selected);
        console.log("selected", selected);
    }, [selected]);

    useEffect(() => {
        updateToggle(selected);
        console.log("toggle", toggle);
    }, [toggle]);

    const updateToggle = (index) => {
        if (containerRef.current && optionRefs.current[index]) {
            const option = optionRefs.current[index];
            const container = containerRef.current;
            const highlight = container.querySelector('.highlighted');
            highlight.classList.remove("visible");
            if(!toggle){
                highlight.style.top = `3px`;
            } else {
                setTimeout(() => {
                    highlight.style.top = `${option.offsetTop}px`;
                    highlight.style.height = `${option.offsetHeight}px`;
                    highlight.classList.add("visible");
                }, 501);
            }
        }
    }
    
    const updateHighlight = (index) => {
        if (containerRef.current && optionRefs.current[index]) {
            const option = optionRefs.current[index];
            const container = containerRef.current;
            const highlight = container.querySelector('.highlighted');

            highlight.style.top = `3px`;
            setToggle(false);
            // if(!toggle){
            // } else {
            //         highlight.style.top = `${option.offsetTop}px`;
            //         highlight.style.height = `${option.offsetHeight}px`;
            //         highlight.classList.add("visible");
            // }
        }
    };
    
    const handleClick = (index) => {
        console.log("clicked", index);
        setSelected(index);
        onChange(index);
    };
    
    const style={
        "--horizontal-padding": `${hPadding}px`,
        "--vertical-padding": `${vPadding}px`,
        "--switch-outer-bradius": `${bRadius}px`,
        "--switch-font-size": `${fSize}px`,
        "--mobile-font-size": `${mFSize}px`
    }

    
    return (
        <div className="mobile-switch" ref={containerRef} style={style} onClick={()=>{setToggle(true)}}>
            <div className="option placeholder">
                <p>f</p>
            </div>
            <div className="switch">
                <div className="highlighted"></div>
                {options.map((option, index) => (
                    <div

                        className={`option ${selected === index ? "selected" : toggle ? "" : "invisible"}`}
                        key={index}
                        onClick={() => handleClick(index)}
                        ref={(el) => (optionRefs.current[index] = el)}
                    >   
                    <p>{option}</p>
                    </div>
                ))}
            </div>
        </div>
    );
}

export default MobileSwitch;