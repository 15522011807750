import React from 'react';
import './ChromeExtensionOutlink.scss';

const ChromeExtensionOutlink = () => {
    return (
        <div>
            <h1>Chrome Extension Outlink</h1>
            <p>This is the Chrome Extension Outlink component.</p>
        </div>
    );
};

export default ChromeExtensionOutlink;