import React, { createContext, useContext, useState } from 'react';
import mockFetch from '../utils/mockFetch';

const DataContext = createContext();

export const useData = () => useContext(DataContext);

export const DataProvider = ({ children }) => {
    const [cache, setCache] = useState({});

    //example usage:
    //await fetchData('/api/data', 'post', { key: 'value' }, { headers: { 'Authorization': 'Bearer token' } });
    // or 
    /*
        example usage:
            const data = await fetchData('/api/data');
            const complicatedData = await fetchData('/api/data', 'post', { key: 'value' }, { headers: { 'Authorization': 'Bearer token' } })
        or
            fetchData(/api/data).then(data => console.log(data));
            
    */
    const getPicketts = async () => {
        return await fetchData('/api/picketts');
    }

    const fetchData = async (url, method = 'get', data = null, options = {}, useCache = true, defaultError = true) => {
        const cacheKey = `${method.toUpperCase()}:${url}${data ? JSON.stringify(data) : ''}${JSON.stringify(options)}`;

        if (useCache && cache[cacheKey]) {
            return cache[cacheKey];
        }

        try {
            const response = await mockFetch({
                url,
                method,
                data,
                ...options,
            });

            const responseData = response.data;

            if (useCache) {
                setCache((prevCache) => ({ ...prevCache, [cacheKey]: responseData }));
            }
            console.log(response);
            return responseData;
        } catch (error) {
            if(defaultError) console.log(error);
            else throw error;
        }
    };

    const clearCache = (url = null, method = 'get') => {
        if (url) {
            const cacheKey = `${method.toUpperCase()}:${url}`;
            setCache((prevCache) => {
                const newCache = { ...prevCache };
                delete newCache[cacheKey];
                return newCache;
            });
        } else {
            setCache({});
        }
    };

    const value = {
        fetchData,
        clearCache,
        getPicketts,
    };

    return <DataContext.Provider value={value}>
        {children}
    </DataContext.Provider>;
};
