import React, { useState, useEffect, useRef } from "react";
import "./AccountSettings.scss";
import { useData } from "../../contexts/DataContext";

import ScrollingBanner from "../../components/ScrollBanner/ScrollBanner";
import MultiSelector from "../../components/MultiSelector/MultiSelector";
import Switch from "../../components/Switch/Switch";

const AccountSettings = () => {
  const { fetchData } = useData();
  const [picketts, setPicketts] = useState([]);
  const [user, setUser] = useState(null);
  const [selectedDisplayValues, setSelectedDisplayValues] = useState({});
  const [modifyingSetting, setModifyingSetting] = useState({
    displayName: false,
    bio: false,
    displayedStats: false,
    pinnedLine: false,
    privateAccount: false,
    username: false,
    password: false,
    email: false,
    verificationStatus: false,
    blockedAccounts: false,
    deleteAccount: false,
  });
  const [newSettings, setNewSettings] = useState({
    displayName: "",
    bio: "",
    displayedStats: [],
    pinnedLine: "",
    privateAccount: "",
    username: "",
    password: "",
    email: "",
    verificationStatus: "",
    blockedAccounts: "",
    deleteAccount: "",
  });
  const changeUsername = useRef(null);
  const changeDisplayName = useRef(null);
  const changeBio = useRef(null);
  const changeDisplayStats = useRef(null);
  const changePinnedLine = useRef(null);
  const changeVisibility = useRef(null);
  const changePassword = useRef(null);
  const changeEmail = useRef(null);
  const changeVerificationStatus = useRef(null);
  const changeBlockedAccounts = useRef(null);
  const changeDeleteAccount = useRef(null);

  const handleSwitchChange = (newState) => {
    if (newState === 0) {
      setNewSettings((prev) => ({
        ...prev,
        privateAccount: "public",
      }));
    } else {
      setNewSettings((prev) => ({
        ...prev,
        privateAccount: "private",
      }));
    }
  };

  useEffect(()=>{
    console.log(newSettings);
  },[newSettings]);

  useEffect(() => {
    fetchData("/api/picketts").then((response) => {
      if (response.success && response.data.length > 0) {
        setPicketts(response.data);
      }
    });
    fetchData("/api/user", "get", null, {
      params: { username: "jonklaumf" },
    }).then((response) => {
      console.log(response);
      if (response.success) {
        setUser(response.data);

        const visibleStats = response.data.settings.visableStats;
        setSelectedDisplayValues((prevState) => ({
          ...prevState,
          ...visibleStats,
        }));
      }
    });
  }, [fetchData]);

  if (!user) {
    return <div></div>;
  }

  const handleChangeSettingClick = (targetSetting) => {
    setModifyingSetting((prev) => ({
      ...prev,
      [targetSetting]: !prev[targetSetting],
    }));
  };

  const handleSettingChange = (e, setting) => {
    if (modifyingSetting[setting]) {
      if (e.target.value !== user[setting]) {
        setNewSettings((prev) => ({
          ...prev,
          [setting]: e.target.value,
        }));
      }
    }
  };

  //   ERROR: FOR SOME REASON I CANNOT FIGURE OUT THIS WORKS FOR EVERYTHING EXCEPT THE FIRST TIME THE USER CLICKS IT. IT just doesn't update the stored values
  // till the second click, i think because the list starts out empty, but i can't really figure it out, might do somthign stupid like just run it twice on first click but
  // there has got to be a better solution.
  const handleSelectorChange = (newState) => {
    setSelectedDisplayValues((prevSelected) => {
      if (newSettings.displayedStats.length === 0) {
        setNewSettings((prev) => ({
          ...prev,
          displayedStats: selectedDisplayValues,
        }));
      }
      const updatedSelected =
        Object.keys(prevSelected).length === 0
          ? newState
          : {
              ...prevSelected,
              ...newState,
            };

      //   not sure any of the code before this in this function does anything. truly no idea why it's not working. Saving this issue for later.
      const selectedStats = Object.keys(updatedSelected).filter(
        (stat) => updatedSelected[stat]
      );

      setNewSettings((prev) => ({
        ...prev,
        displayedStats: selectedStats,
      }));

      return updatedSelected;
    });

    setSelectedDisplayValues(newState);
  };

  return (
    <div className="account-settings page">
      <ScrollingBanner items={picketts.map((pickett) => pickett.name)} />
      <div className="account-settings-bounding">
        <h1 className="account-settings-title"> Account Settings </h1>

        <span className="account-settings-section-wrapper">
          <h2 className="account-settings-subtitle"> Profile Settings</h2>
          <div className="account-settings-section-divider"></div>
        </span>
        <div className="account-settings-content-wrapper">
          <div className="account-settings-row">
            <div className="current-setting-status-wrapper">
              <h3 className="setting-name">Display Name</h3>
              {!modifyingSetting.displayName ? (
                <h4 className="setting-value">
                  {newSettings.displayName || user.displayName}
                </h4>
              ) : (
                <input
                  className="change-setting-input"
                  value={newSettings.displayName}
                  onChange={(e) => handleSettingChange(e, "displayName")}
                  placeholder={newSettings.displayName || user.displayName}
                />
              )}
            </div>
            <div className="change-setting-wrapper">
              <div
                className={`update-setting-button ${
                  modifyingSetting.displayName ? "save" : ""
                }`}
                onClick={() => handleChangeSettingClick("displayName")}
                ref={changeDisplayName}
              >
                {modifyingSetting.displayName
                  ? "update display name"
                  : "change display name"}
              </div>
            </div>
          </div>
          <div className="account-settings-row">
            <div className="current-setting-status-wrapper">
              <h3 className="setting-name">Bio</h3>
              {!modifyingSetting.bio ? (
                <h4 className="setting-value">
                  {(newSettings.bio || user.bio).substring(0, 100).trim() +
                    "..."}
                </h4>
              ) : (
                // temp solution, needs to wrap around to next line in the future, good enough for now though, not gonna lose a user of it.
                <input
                  className="change-setting-input"
                  value={newSettings.bio || user.bio}
                  onChange={(e) => handleSettingChange(e, "bio")}
                  placeholder={newSettings.bio || user.bio}
                  style={{ width: "500px" }}
                />
              )}
            </div>
            <div className="change-setting-wrapper">
              <div
                className={`update-setting-button ${
                  modifyingSetting.bio ? "save" : ""
                }`}
                onClick={() => handleChangeSettingClick("bio")}
                ref={changeBio}
              >
                {modifyingSetting.bio ? "update bio" : "change bio"}
              </div>
            </div>
          </div>
          <div className="account-settings-row">
            <div className="current-setting-status-wrapper">
              <h3 className="setting-name">Displayed Stats</h3>
              {!modifyingSetting.displayedStats ? (
                <h4 className="setting-value">
                  {Object.keys(selectedDisplayValues)
                    .filter((key) => selectedDisplayValues[key])
                    .join(", ") || "all stats hidden"}
                </h4>
              ) : (
                <MultiSelector
                  items={Object.keys(user.settings.visableStats)}
                  selectedItems={selectedDisplayValues}
                  setSelectedItems={handleSelectorChange}
                />
              )}
            </div>
            <div className="change-setting-wrapper">
              <div
                className={`update-setting-button ${
                  modifyingSetting.displayedStats ? "save" : ""
                }`}
                onClick={() => handleChangeSettingClick("displayedStats")}
                ref={changeDisplayStats}
              >
                {modifyingSetting.displayedStats
                  ? "update displayed stats"
                  : "change displayed stats"}
              </div>
            </div>
          </div>
          <div className="account-settings-row">
            <div className="current-setting-status-wrapper">
              <h3 className="setting-name">Pinned Line</h3>
              {!modifyingSetting.pinnedLine ? (
                <h4 className="setting-value">
                  {newSettings.pinnedLine || user.pinnedLine}
                </h4>
              ) : (
                // This works as a placeholder, unsure if pinned line should be here anyways
                // we will need some kind of query or somthing to find lines, or we could just make this be somthing you change on the line page after joining.
                <input
                  className="change-setting-input"
                  value={newSettings.pinnedLine}
                  onChange={(e) => handleSettingChange(e, "pinnedLine")}
                  placeholder={newSettings.pinnedLine || user.pinnedLine}
                />
              )}
            </div>
            <div className="change-setting-wrapper">
              <div
                className={`update-setting-button ${
                  modifyingSetting.pinnedLine ? "save" : ""
                }`}
                onClick={() => handleChangeSettingClick("pinnedLine")}
                ref={changePinnedLine}
              >
                {modifyingSetting.pinnedLine
                  ? "update pinned line"
                  : "change pinned line"}
              </div>
            </div>
          </div>
          <div className="account-settings-row">
            <div className="current-setting-status-wrapper">
              <h3 className="setting-name">Visibility</h3>
              <h4 className="setting-value">
                {newSettings.privateAccount ||
                  (user.settings.privateAccount ? "private" : "public")}
              </h4>
            </div>
            <div className="change-setting-wrapper">
              <Switch
                options={["public", "private"]}
                onChange={handleSwitchChange}
                initialSelected={user.settings.privateAccount ? 1 : 0}
              />
            </div>
          </div>
        </div>

        <span className="account-settings-section-wrapper">
          <h2 className="account-settings-subtitle"> Security Settings</h2>
          <div className="account-settings-section-divider"></div>
        </span>
        <div className="account-settings-content-wrapper">
          <div className="account-settings-row">
            <div className="current-setting-status-wrapper">
              <h3 className="setting-name">username</h3>
              {!modifyingSetting.username ? (
                <h4 className="setting-value">
                  @{newSettings.username || user.username}
                </h4>
              ) : (
                <input
                  className="change-setting-input"
                  value={newSettings.username}
                  onChange={(e) => handleSettingChange(e, "username")}
                  placeholder={newSettings.username || user.username}
                />
              )}
            </div>
            <div className="change-setting-wrapper">
              <div
                className={`update-setting-button ${
                  modifyingSetting.username ? "save" : ""
                }`}
                onClick={() => handleChangeSettingClick("username")}
                ref={changeUsername}
              >
                {modifyingSetting.username
                  ? "update username"
                  : "change username"}
              </div>
            </div>
          </div>
          <div className="account-settings-row">
            <div className="current-setting-status-wrapper">
              <h3 className="setting-name">password</h3>
              {!modifyingSetting.password ? (
                <h4 className="setting-value">
                  {newSettings.password || user.password}
                </h4>
              ) : (
                <input
                  className="change-setting-input"
                  value={newSettings.password}
                  onChange={(e) => handleSettingChange(e, "password")}
                  placeholder={newSettings.password || user.password}
                  type="password"
                />
              )}
            </div>
            <div className="change-setting-wrapper">
              <div
                className={`update-setting-button ${
                  modifyingSetting.password ? "save" : ""
                }`}
                onClick={() => handleChangeSettingClick("password")}
                ref={changePassword}
              >
                {modifyingSetting.password
                  ? "update password"
                  : "change password"}
              </div>
            </div>
          </div>
          <div className="account-settings-row">
            <div className="current-setting-status-wrapper">
              <h3 className="setting-name">email</h3>
              {!modifyingSetting.email ? (
                <h4 className="setting-value">
                  {newSettings.email || user.email}
                </h4>
              ) : (
                <input
                  className="change-setting-input"
                  value={newSettings.email}
                  onChange={(e) => handleSettingChange(e, "email")}
                  placeholder={newSettings.email || user.email}
                  type="email"
                />
              )}
            </div>
            <div className="change-setting-wrapper">
              <div
                className={`update-setting-button ${
                  modifyingSetting.email ? "save" : ""
                }`}
                onClick={() => handleChangeSettingClick("email")}
                ref={changeEmail}
              >
                {modifyingSetting.email ? "update email" : "change email"}
              </div>
            </div>
          </div>
          <div className="account-settings-row">
            <div className="current-setting-status-wrapper">
              <h3 className="setting-name">verification status</h3>
              {!modifyingSetting.verificationStatus ? (
                <h4 className="setting-value">
                  {newSettings.verificationStatus || user.verificationStatus}
                </h4>
              ) : (
                <input
                  className="change-setting-input"
                  value={newSettings.verificationStatus}
                  onChange={(e) => handleSettingChange(e, "verificationStatus")}
                  placeholder={
                    newSettings.verificationStatus || user.verificationStatus
                  }
                />
              )}
            </div>
            <div className="change-setting-wrapper">
              <div
                className={`update-setting-button ${
                  modifyingSetting.verificationStatus ? "save" : ""
                }`}
                onClick={() => handleChangeSettingClick("verificationStatus")}
                ref={changeVerificationStatus}
              >
                {modifyingSetting.verificationStatus
                  ? "update verification status"
                  : "change verification status"}
              </div>
            </div>
          </div>
          <div className="account-settings-row">
            <div className="current-setting-status-wrapper">
              <h3 className="setting-name">blocked accounts</h3>
              {!modifyingSetting.blockedAccounts ? (
                <h4 className="setting-value">
                  {newSettings.blockedAccounts || user.blockedAccounts}
                </h4>
              ) : (
                <input
                  className="change-setting-input"
                  value={newSettings.blockedAccounts}
                  onChange={(e) => handleSettingChange(e, "blockedAccounts")}
                  placeholder={
                    newSettings.blockedAccounts || user.blockedAccounts
                  }
                />
              )}
            </div>
            <div className="change-setting-wrapper">
              <div
                className={`update-setting-button ${
                  modifyingSetting.blockedAccounts ? "save" : ""
                }`}
                onClick={() => handleChangeSettingClick("blockedAccounts")}
                ref={changeBlockedAccounts}
              >
                {modifyingSetting.blockedAccounts
                  ? "update blocked accounts"
                  : "change blocked accounts"}
              </div>
            </div>
          </div>
        </div>

        <span className="account-settings-section-wrapper">
          <h2 className="account-settings-subtitle"> Danger Zone</h2>
          <div className="account-settings-section-divider"></div>
        </span>
        <div className="account-settings-content-wrapper">
          <div className="account-settings-row">
            <div className="current-setting-status-wrapper">
              <h3 className="setting-name">Delete Account</h3>
              {!modifyingSetting.deleteAccount ? (
                <h4 className="setting-value">
                  {newSettings.deleteAccount || user.deleteAccount}
                </h4>
              ) : (
                <input
                  className="change-setting-input"
                  value={newSettings.deleteAccount}
                  onChange={(e) => handleSettingChange(e, "deleteAccount")}
                  placeholder={newSettings.deleteAccount || user.deleteAccount}
                />
              )}
            </div>
            <div className="change-setting-wrapper">
              <div
                className={`update-setting-button ${
                  modifyingSetting.deleteAccount ? "save" : ""
                }`}
                onClick={() => handleChangeSettingClick("deleteAccount")}
                ref={changeDeleteAccount}
              >
                {modifyingSetting.deleteAccount
                  ? "confirm delete account"
                  : "delete account"}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AccountSettings;
