import React from 'react';
import './label.scss';

const Label = ({ className = '', children, label }) => {
  return (
    <div className={`label-container ${className}`}>
      {label && (
        <div className="label-text">
          {label}
        </div>
      )}
      <div className="label-content">
        {children}
      </div>
    </div>
  );
};

export default Label;