import React from 'react';
import './ErrorRedirect.scss';
import Logo from '../../components/Logo/Logo';
import { useNavigate } from 'react-router-dom';

const ErrorRedirect = () => {
    const navigate = useNavigate();
    return (
        <div className="error-redirect page">
            <div className="error-content">
                <Logo size={50} />
                <h1>Page Not Found</h1>
                <p>Sorry, looks like the page you are looking for doesn't exist or has been moved.</p>
                <button onClick={()=>navigate('/')}>go home</button>
            </div>
        </div>
    );
};

export default ErrorRedirect;